import { curry, pick } from 'lodash-es';
import { create, CreatorType, RequestOption } from '@shopby/shop-sdk';
import { HTTPError, parseJSONSafely } from '@shopby/shop-sdk/build/src/utils';
import { getAccessToken } from '@/utils/storage';
import { getPlatform } from '@/utils/common';
import { checkLoginWithAdditionalProcess, getLoginStatus } from '@/utils/auth';
import { ncmBridge } from '@/index';

let controller;

async function fetchDefault<Q, B>(
  options: CreatorType,
  request: RequestOption<Q, B>,
): Promise<Response | (Partial<Response> & { data: object }) | void> {
  const option: RequestInit = {
    ...options,
    ...request,
  };

  /*
   * 상품 상세 > 리뷰 건수 클릭 해서 리뷰 페이지로 이동 시
   * 내가 차단한 회원 리뷰 가져오는 api 무력화
   * TODO : ProductReviewContent.tsx에서 비회원일 때 해당 api를 호출하지 않도록 수정 필요! 2023.10.19
   */
  controller = new AbortController();

  if (request.key === 'getProfileBlockedMembers' && getLoginStatus() === 'NOT_LOGIN' && controller) {
    controller.abort();

    return;
  }

  if (option.headers && request.useAccessToken) {
    checkLoginWithAdditionalProcess();
    Object.assign(option.headers, { accessToken: getAccessToken() });
  }
  const url = options.baseURL + request.url;

  const response = await fetch(url, option);
  const content = await response.text();
  const data = content.length > 0 ? parseJSONSafely(content) : {};
  if (!response.ok) {
    throw new HTTPError(response.status, data.code, data.message);
  }

  return {
    ...pick(response, 'status', 'url', 'type', 'ok'),
    data,
  };
}

const fetchApi = curry(fetchDefault);

export const api = create({
  baseURL: process.env.SHOP_API_URL,
  headerOption: {
    clientId: process.env.CLIENT_ID ?? '',
    platform: getPlatform(),
    'Content-type': 'application/json',
  },
  customHttpRequest: fetchApi,
});

export const storageApi = create({
  baseURL: process.env.STORAGE_API_URL,
  headerOption: {
    clientId: process.env.CLIENT_ID ?? '',
    platform: getPlatform(),
  },
  customHttpRequest: fetchApi,
});

// TODO : 401 일 경우 처리방안 찾기
export const globalHttpErrorHandler = (error: HTTPError) => {
  if (error.status === 401) {
    // 여기까지 올 일이 거의 없을 것.
    alert('로그인해주세요.');
    ncmBridge.expireNativeCacheAndGoMain();
    return;
  }

  alert(error.message);
};
