import React, { createContext, useContext, useRef, useState } from 'react';
import { ModalOpenType, ModalOptions, ModalStateType, PromiseRef } from '@/types/context';
import ModalTemplate from '@/components/common/modal/ModalTemplate';

const ModalOpen = createContext<ModalOpenType>(Promise.reject);
const ModalState = createContext<ModalStateType>({
  handleClose: () => {},
  handleSubmit: () => {},
});

export const useModal = () => useContext(ModalOpen);

export const useModalState = () => useContext(ModalState);

export function ModalContextProvider({ children }: { children: React.ReactNode }) {
  const [modalState, setModalState] = useState<ModalOptions[]>([]);
  const awaitingPromiseRef = useRef<PromiseRef[]>([]);

  const openModal = (options: ModalOptions) => {
    document.body.classList.add('modal-open');
    setModalState([...modalState, options]);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current[modalState.length] = { resolve, reject };
    });
  };

  // TODO: 정리 필요
  const handleClose = (all = false) => {
    if (all) {
      modalState.forEach((message, i) => message?.catchOnCancel && awaitingPromiseRef.current[i]?.reject());
    } else {
      const tailIndex = modalState.length - 1;
      modalState[tailIndex]?.catchOnCancel && awaitingPromiseRef.current[tailIndex]?.reject();
    }
    setModalState(all ? modalState : modalState.slice(0, -1));
    if (modalState.length === 1) {
      document.body.classList.remove('modal-open');
    } else {
      return;
    }
  };

  const handleSubmit = (all = false) => {
    if (all) {
      modalState.forEach((message, i) => awaitingPromiseRef.current[i]?.resolve());
    } else {
      const tailIndex = modalState.length - 1;
      awaitingPromiseRef.current[tailIndex]?.resolve();
    }
    setModalState(all ? [] : modalState.slice(0, -1));
    document.body.classList.remove('modal-open');
  };

  return (
    <ModalOpen.Provider value={openModal}>
      <ModalState.Provider value={{ handleClose, handleSubmit }}>
        {children}
        {modalState.map((modal, index) => (
          <ModalTemplate
            open={modalState.length > 0}
            key={index}
            onSubmit={modal.onConfirm ?? handleSubmit}
            onClose={handleClose}
            {...modal}
          />
        ))}
      </ModalState.Provider>
    </ModalOpen.Provider>
  );
}
