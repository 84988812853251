import { ncmBridge } from '@/index';
import { fetchBoardArticleReplies, PostBoardArticleRepliesRequest, postBoardArticleReplies } from '@/api/shop/board';
import { getLoginStatus } from '@/utils/auth';

import { generateId, isFromNativeApp } from '@/utils/common';
import { getYmd } from '@/utils/dateFormat';
import { GetBoardsBoardNoArticlesArticleNoResponse } from '@shopby/shop-sdk';

import React, { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { ReplyCommentItem, ReplyCommentType } from './ReplyCommentItem';
import NoItemBox from '@/components/common/NoItemBox';

type EventArticleDetailViewProps = {
  article: GetBoardsBoardNoArticlesArticleNoResponse;
  boardNo: string;
  articleNo: string;
  isReplyUsed: boolean;
  popupMode: boolean;
};

const MAX_COMMENT_TEXT_COUNT_LIMIT = 1000;

export default function EventArticleDetailView({
  article,
  boardNo,
  articleNo,
  isReplyUsed = false,
  popupMode = false,
}: EventArticleDetailViewProps) {
  const isFromApp = isFromNativeApp();
  const { content = '', title = '', registerYmdt } = article;
  const [repliedCommentsTotalCount, setRepliedCommentsTotalCount] = useState(0);
  const [repliedComments, setRepliedComments] = useState<ReplyCommentType[]>([]);
  const inputFieldRef = useRef<HTMLTextAreaElement>(null);
  const [commentTextValue, setCommentTextValue] = useState('');
  const [currentPageSize, setCurrentPageSize] = useState(60);
  const [visibleShareBtn, setVisibleShareBtn] = useState(true);

  const {
    isLoading,
    data,
    refetch: refetchReplyArticles,
  } = useQuery(
    ['getReplyArticles', boardNo, articleNo, currentPageSize],
    () => fetchBoardArticleReplies({ boardNo, articleNo }, { page: 1, pageSize: currentPageSize }),
    {
      enabled: isReplyUsed && !!boardNo && !!articleNo,
    },
  );

  const { isLoading: isPostBoardArticleRepliesLoading, mutate: writeBoardArticleReplies } = useMutation(
    ['postBoardArticleReplies'],
    (request: PostBoardArticleRepliesRequest) => postBoardArticleReplies(boardNo, request),
    {
      onSuccess: (res) => {
        if (res.status === 204) {
          refetchReplyArticles();

          setCommentTextValue('');
        }
      },
    },
  );

  const handleRegisterEventArticleComment = () => {
    writeBoardArticleReplies({
      parentBoardArticleNo: Number(articleNo),
      articleContent: commentTextValue,
    });
  };

  const handleMoreReplies = () => {
    setCurrentPageSize(repliedComments.length + 20);
  };

  const fetchListAfterDeletingComment = (articleNo: number) => {
    const foundIndex = repliedComments.findIndex((comment) => articleNo === comment.articleNo);

    if (foundIndex > -1) {
      refetchReplyArticles();
    }
  };

  const handleInputCommentFocus = () => {
    const loggedIn = getLoginStatus() === 'LOGIN';

    if (!loggedIn) {
      ncmBridge.showNativeLoginPopup().then((success: boolean) => {
        if (success) {
          inputFieldRef?.current?.focus();
        }
      });

      return;
    }
  };

  const handleChangeTextArea: ChangeEventHandler<HTMLTextAreaElement> = (ev) => {
    const { value } = ev.target;
    let textValue = value;

    if (textValue.length > MAX_COMMENT_TEXT_COUNT_LIMIT) {
      textValue = value.substring(0, MAX_COMMENT_TEXT_COUNT_LIMIT);
    }

    setCommentTextValue(textValue);
  };

  const handleClickShareBtn = async () => {
    try {
      const shareData = {
        title,
        url: location.href,
      };

      if (isFromApp) {
        ncmBridge.nativeShare(shareData);
      } else {
        if (navigator.share) {
          await navigator.share(shareData);
        } else if (navigator.clipboard) {
          navigator.clipboard.writeText(shareData.url).then(() => alert('링크가 복사되었습니다.'));
        }
      }
    } catch (e) {
      console.log('공유 실패', e);
    }
  };

  useEffect(() => {
    if (!isLoading && data) {
      setRepliedComments((data.items ?? []) as ReplyCommentType[]);
      setRepliedCommentsTotalCount(data.totalCount);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (!isFromApp && !navigator.share && !navigator.clipboard) {
      setVisibleShareBtn(false);
    }
  }, []);

  return (
    <div
      className={`event-article-detail-container${popupMode ? ' popup-mode' : ''}${isFromApp ? '' : ' without-reply'}`}
    >
      <div className="event-article-detail-view">
        <section className="event-detail-view-container">
          <div className="event-detail-view-top">
            <p className="event-detail-view-title">{title}</p>
            <p className="event-article-detail-view__sub-paragraph">
              <span className="article-sub-text">{getYmd(registerYmdt)}</span>
              {visibleShareBtn && (
                <button
                  type="button"
                  className="event-article-detail-view__btn-share"
                  onClick={handleClickShareBtn}
                ></button>
              )}
            </p>
          </div>

          <div className="reply-list-item-container">
            <p
              className={`reply-list-item-container__content ${isFromApp ? 'from-app' : 'from-web'}`}
              dangerouslySetInnerHTML={{ __html: content }}
            ></p>
          </div>
        </section>

        {isReplyUsed && (
          <section className="reply-comment-container">
            <p className="total-count">총 {repliedCommentsTotalCount}개</p>
            {repliedComments.length === 0 ? (
              <NoItemBox type="comments" text="댓글이 없습니다." />
            ) : (
              <ul className="replay-comments">
                {repliedComments.map((replyComment) => (
                  <ReplyCommentItem
                    key={generateId('reply-comment-item')}
                    replyComment={replyComment}
                    boardNo={boardNo}
                    deleteReplyCommentItem={fetchListAfterDeletingComment}
                  />
                ))}
              </ul>
            )}
            {repliedCommentsTotalCount > repliedComments.length && (
              <div className="reply-comment-more-btn-wrapper">
                <button
                  type="button"
                  className="more-btn"
                  disabled={isPostBoardArticleRepliesLoading}
                  onClick={handleMoreReplies}
                >
                  댓글 더보기
                </button>
              </div>
            )}
          </section>
        )}
      </div>

      {isReplyUsed && isFromApp && (
        <div className="reply-comment-input-container">
          <div className="reply-comment-input-wrapper">
            <button
              type="button"
              className="write-btn"
              onClick={handleRegisterEventArticleComment}
              disabled={isPostBoardArticleRepliesLoading || commentTextValue.trim().length === 0}
            >
              등록
            </button>
            <textarea
              ref={inputFieldRef}
              placeholder="댓글을 입력해 주세요."
              value={commentTextValue}
              onFocus={handleInputCommentFocus}
              onChange={handleChangeTextArea}
            />
          </div>
        </div>
      )}
    </div>
  );
}
