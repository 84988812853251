import EventContainer from '@/components/mypage/event/EventContainer';
import { useConfigState } from '@/context/config.context';
import React, { useMemo } from 'react';

export const EVENT_BOARD_ID = 'event';

export default function Event() {
  const { boardsConfig } = useConfigState();

  const eventBoardConfig = useMemo(() => {
    const eventBoard = boardsConfig?.boardConfigs.find(({ boardId }) => boardId === EVENT_BOARD_ID);

    return eventBoard ?? null;
  }, [boardsConfig]);

  return eventBoardConfig ? <EventContainer eventBoardConfig={eventBoardConfig} /> : <></>;
}
