import { getAccessToken } from '@/utils/storage';
import { ClientError } from '@/utils/error';
import { clientErrorCode } from '@/const/errorCode';

export type LoginStatus = 'LOGIN' | 'NOT_LOGIN' | 'EXPIRED';
export const getLoginStatus = (): LoginStatus => {
  const hasAccessToken = Boolean(getAccessToken(false));
  if (hasAccessToken) {
    const isNotExpired = Boolean(getAccessToken(true));
    return isNotExpired ? 'LOGIN' : 'EXPIRED';
  }
  return 'NOT_LOGIN';
};

export const checkLoginWithAdditionalProcess = () => {
  const loginStatus = getLoginStatus();
  switch (loginStatus) {
    case 'NOT_LOGIN':
      throw new ClientError(clientErrorCode.TOKEN_EMPTY, 'Client Token Error');
    case 'EXPIRED':
      throw new ClientError(clientErrorCode.TOKEN_EXPIRED, 'Client Token Error');
    default:
      return;
  }
};
