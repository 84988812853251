import dayjs from 'dayjs';
import { SelectBoxOptions } from '@/types/utils';

const ymdFormat = 'YYYY.MM.DD';
const ymdtFormat = 'YYYY-MM-DD HH:mm:ss';

export const getSubtractYmd = (value: number, format = ymdtFormat) => {
  return dayjs().subtract(value).format(format);
};

export const getYmd = (value: string, format = ymdFormat) => {
  return dayjs(value).format(format);
};

export const changeDateFormat = (value: string, format: string) => {
  return dayjs(value).format(format);
};

/**
 * @return 120년 전까지의 년도 리스트
 */
export const getLast120YearsAgo = (): SelectBoxOptions => {
  const year = new Date().getFullYear();

  return [...Array(120).keys()].reduce(
    (yearList, index) => {
      return [...yearList, { key: year - index, value: `${year - index}년` }];
    },
    [{ key: 0, value: '년도' }],
  );
};
/**
 * [월, 1월 ~ 12월]
 */
export const getMonthList = (): SelectBoxOptions => {
  return [...Array(13).keys()].map((index) => ({ key: index, value: `${index === 0 ? '' : index}월` }));
};
/**
 * @return {month}월에 해당하는 day list
 */
export const getDayList = (month = 1): SelectBoxOptions => {
  const year = new Date().getFullYear();
  const days = new Date(year, month, 0).getDate();

  return [...Array(days + 1).keys()].map((index) => ({ key: index, value: `${index === 0 ? '' : index}일` }));
};
