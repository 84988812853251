import { useEffect, useState } from 'react';
import { useGetDefaultProductGuideContentQuery, useGetProductQuery } from './queries';

export function useProductClaimGuide(productNo: number) {
  const [guide, setGuide] = useState('');
  const [defaultGuide, setDefaultGuide] = useState('');

  const { data: productDetailResponse, isLoading: isProductDetailDataLoading } = useGetProductQuery(productNo);

  const { data: defaultProductGuideResponse, isLoading: isDefaultProductGuideLoading } =
    useGetDefaultProductGuideContentQuery(true);

  useEffect(() => {
    if (!isDefaultProductGuideLoading && typeof defaultProductGuideResponse?.data.content === 'string') {
      setDefaultGuide(defaultProductGuideResponse.data.content);
    }
  }, [isDefaultProductGuideLoading]);

  useEffect(() => {
    if (!isProductDetailDataLoading && typeof productDetailResponse?.data.baseInfo.contentFooter === 'string') {
      setGuide(productDetailResponse.data.baseInfo.contentFooter);
    }
  }, [isProductDetailDataLoading]);

  return { guide, defaultGuide };
}
