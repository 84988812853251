import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  BlurInputReturn,
  ChangeInputType,
  CheckValueToggle,
  FocusInvalidReturn,
  RegexType,
  ToggleReturn,
  ValidateInputReturn,
} from '@/types/hooks';
import regex from '@/const/regex';
import { useModal } from '@/context/modal.context';
import { CheckOption } from '@/types/utils';
import { debounce, throttle } from 'lodash-es';
import { useNavigationType } from 'react-router-dom';
import { storage } from '@/utils/storage';
import { MessageType } from '@/types/constants';
import { Swiper } from 'swiper';

/*
 * boolean 값을 계속 토글시켜주는 훅
 */
export const useToggle = (initial: boolean): ToggleReturn => {
  const [toggleValue, setToggleValue] = useState(initial);

  const toggle = (newValue?: boolean) => {
    setToggleValue(newValue ?? !toggleValue);
  };

  return [toggleValue, toggle];
};

/*
 * @author choisohyun
 * 입력 불가한 값을 regex 로 판별하여 입력하지 못하도록 막는 훅
 */
export const useValidateInput = (
  initValue = '',
  changeRegKey?: RegexType,
  onValidateInputChange?: (changedString: string) => void,
): ValidateInputReturn => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(initValue);
  }, [initValue]);

  const changeInputValue = (e: ChangeInputType) => {
    const { value } = e.target;
    const changedValue = changeRegKey ? value.replace(regex[changeRegKey], '') : value;
    setInputValue(changedValue);
    onValidateInputChange?.(changedValue);
  };

  return [inputValue, changeInputValue];
};

/*
 * @author choisohyun
 * 밸리데이션에 통과하지 못하면 알럿 띄운 후 인풋에 포커스를 주는 훅
 */
export const useFocusInvalid = (checkOption: CheckOption): FocusInvalidReturn => {
  const modal = useModal();
  const inputRef = useRef<any>(null);
  const [checkValue, setCheckValue] = useToggle(false);

  const toggle = useCallback<CheckValueToggle>((nextCheck = true, newValue = !checkValue) => {
    nextCheck && setCheckValue(newValue);
    return checkValidation();
  }, []);

  useEffect(() => {
    if (checkValue && !checkValidation()) {
      modal({ title: '알림', description: checkOption?.description }).then(() => {
        inputRef.current?.focus();
        setCheckValue(false);
      });
    }
  }, [checkValue]);

  const checkValidation = (): boolean => {
    const inputValue = inputRef.current.type === 'checkbox' ? inputRef.current?.checked : inputRef.current?.value ?? '';

    if (checkOption?.require) {
      return typeof inputValue !== 'boolean' ? inputValue?.length > 0 : inputValue;
    }
    if (checkOption?.regKey) {
      // FIXME: 여기서 자꾸 false가 되는것같아서 임시처리. 수정필요
      // return regex[checkOption.regKey].test(inputValue);
      return true;
    }
    return true;
  };

  return [checkValue && checkValidation(), toggle, inputRef];
};

/*
 * @author choisohyun
 * 인풋에서 블러 시 밸리데이션이 성공이 아니면 메시지타입을 저장하는 훅
 */
export const useValidateBlur = (): BlurInputReturn => {
  const [invalidTextType, setInvalidTextType] = useState<MessageType | ''>('');
  const invalid = useMemo(() => !invalidTextType.includes('success') && invalidTextType !== '', [invalidTextType]);

  const handleBlur = async (checkValidation: () => Promise<boolean>) => {
    const messageType = await checkValidation?.();
    messageType && setInvalidTextType(messageType as unknown as MessageType);
  };

  return [invalidTextType, invalid, handleBlur];
};

/*
 * @author choisohyun
 * 뒤로가기 시 스크롤 유지를 위한 훅
 */
export const useScroll = () => {
  const navigationType = useNavigationType();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const MAX_SYNC_ATTEMPT = 5;
    if (navigationType === 'PUSH') {
      window.scrollTo(0, 0);
      return;
    }

    storage.set('scrollRestoration', true);
    const { state } = window.history;

    if (state?.scroll && navigationType === 'POP') {
      const { x, y, attempt = MAX_SYNC_ATTEMPT } = state.scroll;
      syncScroll(x, y, attempt);
    } else {
      window.scrollTo(0, 0);
    }
  }, [navigationType]);

  const handleScroll =
    // WARN : safari 등 브라우저에서 history 변경 여러번하면 SecurityError 발생합니다.
    // 때문에 throttle 1000ms 로 제한을 둡니다.
    throttle(() => {
      const { pageXOffset, pageYOffset, location } = window;
      const { state: prevState = {} } = window.history;

      window.history.replaceState(
        {
          ...prevState,
          scroll: {
            x: pageXOffset,
            y: pageYOffset,
            height: document.body.scrollHeight,
          },
        },
        '',
        location.href,
      );
    }, 1000);

  const syncScroll = useCallback(
    debounce((x, y, attempt) => {
      // WARN: requestAnimationFrame이 필요한지 확인 필요
      requestAnimationFrame(() => {
        const { pageXOffset, pageYOffset } = window;
        if (x !== pageXOffset || y !== pageYOffset) {
          window.scrollTo(x, y);
          syncScroll(x, y, attempt - 1);
        } else {
          storage.remove('scrollRestoration');
        }
      });
    }, 100),
    [],
  );
};

export type StateLinkType<T> = { value: T; set: Dispatch<SetStateAction<T>> };
export function useStateLink<T>(initialValue: T): StateLinkType<T> {
  const [value, set] = useState<T>(initialValue);
  return { value, set };
}

export const useSwiper = () => {
  const [swiper, setSwiper] = useState<Swiper | null>(null);

  const handleSwiperInit = (swiper: Swiper) => {
    setSwiper(swiper);
  };

  return { swiper, handleSwiperInit } as const;
};

export const useThrottle = <T extends any[]>(callback: (...params: T) => void, time: number) => {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  return (...params: T) => {
    if (!timer.current) {
      callback(...params);

      timer.current = setTimeout(() => {
        timer.current = null;
      }, time);
    }
  };
};
