import { ValueOf } from '@shopby/shop-sdk';
import { clientErrorCode } from '@/const/errorCode';
import { HTTPError } from '@shopby/shop-sdk/build/src/utils';
import { globalHttpErrorHandler } from '@/api/shophttpRequest';
import { ncmBridge } from '@/index';

export const getErrorMessage = (error: unknown) => {
  if (error instanceof Error) return error.message;
  return String(error);
};

export class ClientError extends Error {
  public code: ValueOf<typeof clientErrorCode>;
  constructor(code: ValueOf<typeof clientErrorCode>, message?: string) {
    super();
    this.code = code;
    if (message) {
      this.message = message;
    }
  }
}

export const clientErrorHandler = async (error: ClientError): Promise<boolean> => {
  switch (error.code) {
    case clientErrorCode.TOKEN_EMPTY: {
      const isLoginSuccess = await ncmBridge.showNativeLoginPopup();
      if (isLoginSuccess) {
        location.replace(location.href);
      }
      return true;
    }
    case clientErrorCode.TOKEN_EXPIRED: {
      alert('정보 보호와 안전한 사용을 위해 지정된 시간이 초과되어 자동 로그아웃 되었습니다.\n재 로그인 해주세요.');
      ncmBridge.expireNativeCacheAndGoMain();
      return true;
    }
    default:
      return false;
  }
};

export const defaultErrorHandler = async (error: HTTPError | ClientError) => {
  const isClientError = await clientErrorHandler(error as ClientError);
  if (!isClientError) {
    globalHttpErrorHandler(error as HTTPError);
  }
};
