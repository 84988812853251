import React from 'react';
import classnames from 'classnames';

interface IconProps {
  type: 'info';
  txt?: number;
}

const Icon: React.FC<IconProps> = ({ type, txt }) => {
  const currentType = `ico--${type}`;
  return <span className={classnames('ico', currentType)}>{txt && <span>{txt}</span>}</span>;
};

export default Icon;
