import React, { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import Layout from '@/pages/Layout';
import LoginForm from '@/components/member/LoginForm';
import ProductDetailClaimGuide from '@/pages/product/ProductDetailClaimGuide';
import ProductDetailPartner from '@/pages/product/ProductDetailPartner';
import EventArticleDetail from '@/pages/mypage/EventArticleDetail';
import EventArticleReport from '@/pages/mypage/event/EventArticleReport';
import { isFromNativeApp } from '@/utils/common';

const getComponent = (componentPath: string) => {
  return lazy(() => import(/* webpackChunkName: "[request]" */ `@/pages/${componentPath}`));
};

const ProductView = getComponent('product/ProductDetail');
const ProductDetailView = getComponent('product/ProductDetailView');
const ProductDetailReview = getComponent('product/ProductDetailReview');
const ProductDetailInquiry = getComponent('product/ProductDetailInquiry');
const ProductBuy = getComponent('product/ProductBuy');
const NotFound = getComponent('error/NotFound');
const MyPage = getComponent('mypage/MyPage');
const OneToOneInquiries = getComponent('mypage/OneToOneInquiries');
const ProductInquiries = getComponent('mypage/ProductInquiries');
const Accumulation = getComponent('mypage/Accumulation');
const OrderSheet = getComponent('order/OrderSheetController');
const OrderComplete = getComponent('order/OrderCompleteController');
const TermsOfUse = getComponent('mypage/policy/TermsOfUse');
const Privacy = getComponent('mypage/policy/Privacy');
const PrivacyAgreement = getComponent('mypage/policy/PrivacyAgreement');
const EventPromotion = getComponent('mypage/policy/EventPromotion');
const Withdrawal = getComponent('mypage/policy/Withdrawal');
const CancelLationPolicy = getComponent('mypage/policy/CancelLationPolicy');
const OpenSourceLicense = getComponent('mypage/policy/OpenSourceLicense');
const OrderList = getComponent('mypage/OrderList');
const CancelRequest = getComponent('claim/CancelRequestController');
const ReturnRequest = getComponent('claim/ReturnRequestController');
const ExchangeRequest = getComponent('claim/ExchangeRequestController');
const OrderDetail = getComponent('mypage/OrderDetailController');
const Faq = getComponent('mypage/Faq');
const Notice = getComponent('mypage/Notice');
const Event = getComponent('mypage/Event');
const ArticleDetail = getComponent('mypage/ArticleDetail');
const ShippingAddress = getComponent('mypage/ShippingAddress');
const Coupon = getComponent('mypage/Coupon');
const CartList = getComponent('order/CartListContorller');
const ReviewList = getComponent('mypage/ReviewList');
const ReviewDetail = getComponent('mypage/ReviewDetail');
const PhotoReviews = getComponent('product/ProductPhotoReviewsDetail');

interface RouteType extends RouteObject {
  title?: string;
}
/*
 * Layout type
 * 1. Main. 로고 검색 장바구니
 * 2. default. 이전 타이틀 검색 장바구니
 * 3. Product view. 이전 로고 검색 장바구니
 * 4. prev title. 이전 타이틀
 * etc. no header. 헤더 없음 페이지 or element 내부에서 헤더 삽입
 * */
// TODO 개발 하면서 헤더에 맞게 추가해 주세요.
export default [
  {
    path: '/',
    element: <NotFound />,
  },
  { path: '*', element: <NotFound /> },
  {
    path: '/',
    element: <Layout />, // 2. default. 이전 타이틀 검색 장바구니
    children: [
      // development
      { path: 'dev/login', element: <LoginForm />, title: '로그인' },
      //마이페이지
      { path: '/member/mypage', element: <MyPage />, title: '마이 페이지' },
      { path: '/mypage/accumulation', element: <Accumulation />, title: '적립금' },
      { path: '/mypage/product-inquiry', element: <ProductInquiries />, title: '상품 문의' },
      { path: '/mypage/order', element: <OrderList />, title: '나의 주문내역' },
      { path: '/mypage/info/cancel-lation-policy', element: <CancelLationPolicy />, title: '청약철회 안내' },
      { path: '/mypage/shipping-address', element: <ShippingAddress />, title: '배송지 관리' },
      { path: '/mypage/coupon', element: <Coupon />, title: '쿠폰' },
      { path: '/mypage/review/', element: <ReviewList />, title: '리뷰' },
      { path: '/mypage/review/view/:productNo/:reviewNo', element: <ReviewDetail />, title: '나의 리뷰' },
      { path: '/mypage/coupon', element: <Coupon />, title: '쿠폰 등록/조회' },
    ],
  },
  {
    path: '/',
    element: <Layout hasBgChange={false} hasPrevBtn={isFromNativeApp()} />, //
    children: [
      //
      { path: '/mypage/info/notice', element: <Notice />, title: '공지사항' },
      { path: '/mypage/info/notice/:articleNo', element: <ArticleDetail />, title: '공지사항' },
      { path: '/mypage/info/faq', element: <Faq />, title: 'FAQ' },
      { path: '/mypage/info/faq/:articleNo', element: <ArticleDetail />, title: 'FAQ' },
      { path: '/mypage/info/inquiry', element: <OneToOneInquiries />, title: '1:1문의 내역' },
      { path: '/mypage/info/license', element: <OpenSourceLicense />, title: '오픈소스라이선스' },
      { path: '/mypage/info/event', element: <Event />, title: '이벤트' },
      { path: '/mypage/info/event/:articleNo', element: <EventArticleDetail />, title: '이벤트' },
      { path: '/mypage/info/event/:articleNo/report', element: <EventArticleReport />, title: '댓글 신고' },
    ],
  },
  {
    path: '/popup/',
    element: <Layout hasHeader={false} hasBgChange={false} />,
    children: [
      { path: 'mypage/info/event/:articleNo', element: <EventArticleDetail popupMode={true} /> },
      { path: 'mypage/info/event/:articleNo/report', element: <EventArticleReport />, title: '댓글 신고' },
    ],
  },
  {
    path: '/',
    element: <Layout hasToolbar={false} hasBgChange={false} />, // 3. Product view. 이전 로고 검색 장바구니 TODO: UI 작업 후 변경
    children: [
      // 상품
      { path: 'product/view/:productNo', element: <ProductView /> },
      { path: 'product/view/:productNo/detail', element: <ProductDetailView />, title: '상품 상세' },
      { path: 'product/view/:productNo/review', element: <ProductDetailReview />, title: '리뷰' },
      { path: 'product/view/:productNo/inquiry', element: <ProductDetailInquiry />, title: '상품 문의' },
      { path: 'product/view/:productNo/guide', element: <ProductDetailClaimGuide />, title: '배송/반품/취소 안내' },
      { path: 'product/view/:productNo/partner', element: <ProductDetailPartner />, title: '판매자 정보' },
      { path: '/photo-review/:productNo', element: <PhotoReviews />, title: '포토리뷰' },
      { path: '/cart/', element: <CartList />, title: '오더' },
      { path: '/mypage/order/:orderNo', element: <OrderDetail />, title: '주문/배송 상세' },
    ],
  },
  {
    path: '/',
    element: <Layout hasToolbar={false} hasFooter={false} hasBgChange={false} />,
    children: [{ path: 'order/:orderSheetNo', element: <OrderSheet />, title: '주문/결제' }],
  },
  {
    path: '/',
    element: <Layout hasToolbar={false} hasFooter={false} hasPrevBtn={false} hasBgChange={false} />,
    children: [{ path: 'order/complete', element: <OrderComplete /> }], // 주문완료
  },
  {
    // 클레임
    path: '/',
    element: <Layout hasToolbar={false} hasFooter={false} hasBgChange={false} />,
    children: [
      { path: 'claim/cancel/:orderOptionNo', element: <CancelRequest />, title: '취소신청' },
      { path: 'claim/return/:orderOptionNo', element: <ReturnRequest />, title: '반품신청' },
      { path: 'claim/exchange/:orderOptionNo', element: <ExchangeRequest />, title: '교환신청' },
    ],
  },
  { path: 'product/buy/:productNo', element: <ProductBuy /> },
  // 정책
  { path: '/mypage/info/agreement', element: <TermsOfUse />, title: '이용약관' },
  { path: '/mypage/info/privacy-policy', element: <Privacy />, title: '개인정보처리방침' },
  {
    path: '/mypage/info/privacy-agreement',
    element: <PrivacyAgreement />,
    title: '개인정보 수집/이용',
  },
  { path: '/mypage/info/withdrawal', element: <Withdrawal />, title: '탈퇴 안내' },
  { path: '/mypage/info/event-promotion', element: <EventPromotion />, title: '이벤트 및 프로모션 알림' },
] as RouteType[];
