import React, { useEffect, useState } from 'react';
import Header from '@/components/common/Header';
import { Outlet } from 'react-router-dom';
import { useScroll } from '@/hooks/common';

interface LayoutProps {
  showLogo?: boolean;
  hasToolbar?: boolean;
  hasHeader?: boolean;
  hasRightArea?: boolean;
  hasFooter?: boolean;
  hasPrevBtn?: boolean;
  hasBgChange?: boolean;
}

const Layout: React.FC<LayoutProps> = ({
  hasToolbar = true,
  hasHeader = true,
  hasPrevBtn = true,
  hasBgChange = true,
}) => {
  if (!hasHeader) hasPrevBtn = false;

  const [scrollTop, setScrollTop] = useState(0);
  const [isShowToolbar, setIsShowToolbar] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  useScroll();

  useEffect(() => {
    const handleScroll = () => {
      if (hasToolbar) {
        setIsShowToolbar(window.scrollY <= scrollTop || window.scrollY <= 0);
      }
      setScrollTop(window.scrollY);
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollTop, isShowToolbar, isScrolled]);

  return (
    <div className={`l-wrap ${isShowToolbar ? 'sticked-toolbar' : ''} ${hasBgChange ? '' : 'l-bg-white'}`}>
      {hasHeader && <Header hasPrevBtn={hasPrevBtn} />}
      <main className="l-content">
        <Outlet />
        <div className="fixed-bg">
          <span className="a11y-hidden">고정 뒷배경</span>
        </div>
      </main>
    </div>
  );
};

export default Layout;
