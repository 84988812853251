import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ModalContextProvider } from '@/context/modal.context';
import routes from '@/routes/index';
import { ConfigProvider } from '@/context/config.context';
import { HTTPError } from '@shopby/shop-sdk/build/src/utils';
import { ClientError, defaultErrorHandler } from '@/utils/error';
import LoadingBar from '@/components/common/LoadingBar';
import { androidStatusBarHeight } from '@/utils/common';
import { ncmBridge } from '@/index';

const App = () => {
  ncmBridge.useAppBackBtnHandler();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        onError: (error) => {
          defaultErrorHandler(error as HTTPError | ClientError);
        },
      },
      mutations: {
        onError: (error) => {
          defaultErrorHandler(error as HTTPError | ClientError);
        },
      },
    },
  });
  const element = useRoutes(routes);

  androidStatusBarHeight();

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider>
        <ModalContextProvider>
          <Suspense fallback={<LoadingBar />}>{element}</Suspense>
        </ModalContextProvider>
      </ConfigProvider>
    </QueryClientProvider>
  );
};

export default App;
