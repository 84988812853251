import React, { useCallback, useEffect, useRef } from 'react';

interface IntersectionLoaderProps {
  fetch: () => Promise<void> | void;
  isReady: boolean;
}

export default function IntersectionLoader({ fetch, isReady }: IntersectionLoaderProps) {
  const area = useRef<HTMLDivElement>(null);

  const handleObserver = useCallback(async ([entry]: IntersectionObserverEntry[], observer: IntersectionObserver) => {
    if (entry.isIntersecting) {
      observer.unobserve(entry.target);
      await fetch();
      observer.observe(entry.target);
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, { threshold: 0.5 });
    area.current && observer.observe(area.current);
  }, [handleObserver]);

  return (
    <div
      ref={area}
      className="intersection-area"
      style={{ display: isReady ? 'block' : 'none', backgroundColor: '#ccc' }}
    >
      이 영역이 화면에 절반이상 노출되면 추가로딩이 되요!
    </div>
  );
}
