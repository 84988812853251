import React from 'react';
interface NoItemBoxProps {
  type: 'comments';
  text: string;
}

export default function NoItemBox({ type, text }: NoItemBoxProps) {
  return (
    <div className="no-item-box">
      <i className={`icon icon--${type}`} />
      <p className="no-item-box__message" dangerouslySetInnerHTML={{ __html: text }}></p>
    </div>
  );
}
