import IntersectionLoader from '@/components/common/IntersectionLoader';
import NoItem from '@/components/common/NoItem';
import { useGetBoardsBoardNoArticlesInfiniteQuery } from '@/hooks/board/queries/board';
import { generateId } from '@/utils/common';
import { BoardsConfigurationsBoardConfig } from '@shopby/shop-sdk';
import React from 'react';
import EventArticleListItem from './EventArticleListItem';

type EventContainerProps = {
  eventBoardConfig: BoardsConfigurationsBoardConfig;
};

export default function EventContainer({ eventBoardConfig }: EventContainerProps) {
  const { boardNo, thumbnailUsed, replyUsed } = eventBoardConfig;
  const { data, fetchNextPage, hasNextPage, isFetching } = useGetBoardsBoardNoArticlesInfiniteQuery(boardNo.toString());

  return !data?.pages.length ? (
    <NoItem type="notice" text="등록된 내용이 없어요." />
  ) : (
    <>
      <ul>
        {data.pages.map((article) => (
          <EventArticleListItem
            key={generateId('event-list-items')}
            article={article}
            isThumbnailUsed={thumbnailUsed}
            isReplyUsed={replyUsed}
          />
        ))}
      </ul>
      <IntersectionLoader
        isReady={!isFetching && hasNextPage === true}
        fetch={() => {
          fetchNextPage();
        }}
      />
    </>
  );
}
