import React from 'react';
import classnames from 'classnames';

interface NoItemProps {
  type: 'inquiry' | 'coupon' | 'order' | 'notice' | 'mypage' | 'accumulated' | 'cart' | 'review';
  text: string;
}

export default function NoItem({ type, text }: NoItemProps) {
  return (
    <div
      className={classnames('empty-result', `empty-result--${type}`)}
      dangerouslySetInnerHTML={{ __html: text }}
    ></div>
  );
}
