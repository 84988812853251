import React from 'react';
import { SvgIconProps } from './typings';

export default function IconSiren({ size = 16 }: SvgIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.98858 2.0166C5.75911 2.0166 3.95177 3.82394 3.95177 6.05341V9.16095H3.87085C2.97339 9.16095 2.24585 9.88849 2.24585 10.7859V11.9613C2.24585 12.8587 2.97339 13.5863 3.87085 13.5863H12.1063C13.0038 13.5863 13.7313 12.8587 13.7313 11.9613V10.7859C13.7313 9.88849 13.0038 9.16095 12.1063 9.16095H12.0254V6.05342C12.0254 3.82394 10.2181 2.0166 7.98858 2.0166ZM4.56613 10.4109C4.56967 10.411 4.57322 10.411 4.57677 10.411H11.4004C11.4039 10.411 11.4075 10.411 11.411 10.4109H12.1063C12.3134 10.4109 12.4813 10.5788 12.4813 10.7859V11.9613C12.4813 12.1684 12.3134 12.3363 12.1063 12.3363H3.87085C3.66374 12.3363 3.49585 12.1684 3.49585 11.9613V10.7859C3.49585 10.5788 3.66374 10.4109 3.87085 10.4109H4.56613ZM10.7754 9.16095V6.05342C10.7754 4.5143 9.5277 3.2666 7.98858 3.2666C6.44947 3.2666 5.20177 4.5143 5.20177 6.05341V9.16095H10.7754Z"
        fill="#D4D4D4"
      />
    </svg>
  );
}
