import { api } from '@/api/shophttpRequest';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { getLoginStatus } from '@/utils/auth';
import { HTTPError, PostProfileRecentProductsRequest } from '@shopby/shop-sdk';
import { defaultErrorHandler } from '@/utils/error';
import { useNavigateBack } from '@/hooks/route';

export const useGetProductQuery = (productNo: number) => {
  const navigateBack = useNavigateBack();
  const loggedIn = getLoginStatus() === 'LOGIN';
  return useQuery(
    ['product'],
    () =>
      api.product.getProductsProductNo({
        pathVariable: { productNo },
        useAccessToken: loggedIn,
      }),
    {
      suspense: true,
      onError(error: HTTPError) {
        defaultErrorHandler(error);

        if (error.status === 403 || error.status === 404) {
          navigateBack();
        }
      },
    },
  );
};

export const useGetProductOptionsQuery = (productNo: number) => {
  const loggedIn = getLoginStatus() === 'LOGIN';
  return useQuery(
    ['product', 'options'],
    // sdk 리턴타입 잘못됨
    () => api.product.getProductsProductNoOptions({ pathVariable: { productNo }, useAccessToken: loggedIn }),
  );
};

/**
 * 프리미엄 라인업은 배송/반품/취소 안내 관리하는 곳이 따로 없어 기본 양식을 게시판 정적 콘텐츠로 괸라한다.
 */
const defaultProductGuideBoardContent = {
  alpha: {
    boardNo: '8963',
    articleNo: '62462',
  },
  real: {
    boardNo: '183241',
    articleNo: '27791',
  },
} as const;

export const useGetDefaultProductGuideContentQuery = (enabled = false) =>
  useQuery(
    ['board'],
    () =>
      api.manage.getBoardsBoardNoArticlesArticleNo({
        pathVariable: {
          ...defaultProductGuideBoardContent[process.env.BUILD_ENV],
        },
        useAccessToken: false,
      }),
    {
      enabled,
    },
  );

export const useProductsProductNoInquiresQuery = (productNo: number) =>
  useQuery(['product', 'inquiries', 'count'], ({ pageParam = 1 }) =>
    api.display.getProductsProductNoInquires({
      pathVariable: {
        productNo,
      },
      queryString: {
        hasTotalCount: true,
        isMyInquiries: false,
        pageNumber: pageParam,
        pageSize: 1000,
      },
    }),
  );

export const PRODUCT_INQUIRIES_PAGE_SIZE = 10;
// useInfiniteQuery 원래 이렇게 복잡한가?
export const useProductsProductNoInquiresInfiniteQuery = (productNo: number) =>
  useInfiniteQuery(
    ['product', 'inquiries', productNo],
    ({ pageParam = 1 }) =>
      api.display
        .getProductsProductNoInquires({
          pathVariable: {
            productNo,
          },
          queryString: {
            hasTotalCount: true,
            isMyInquiries: false,
            pageNumber: pageParam,
            pageSize: PRODUCT_INQUIRIES_PAGE_SIZE,
          },
        })
        .then(({ data }) => ({
          data,
          nextPage: pageParam + 1,
          isLast: 0 >= data.totalCount - PRODUCT_INQUIRIES_PAGE_SIZE * pageParam,
        })),
    {
      getNextPageParam: (lastPage) => (lastPage.isLast ? undefined : lastPage.nextPage),
      select: (data) => ({ ...data, pages: data.pages.flatMap((page) => page.data.items) }),
    },
  );

export const usePostProfileRecentProducts = () =>
  useMutation(['postProfileRecentProducts'], (request: PostProfileRecentProductsRequest) =>
    api.product.postProfileRecentProducts({ ...request }),
  );

export const useGetProfileRecentProducts = () => {
  const loggedIn = getLoginStatus() === 'LOGIN';
  return useQuery(['getProfileRecentProducts'], () =>
    api.product.getProfileRecentProducts({
      useAccessToken: loggedIn,
    }),
  );
};
