import React, { InputHTMLAttributes } from 'react';
import classnames from 'classnames';

export interface RadioGroupProps extends Pick<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'disabled'> {
  name: string;
  currentValue: string;
  options: OptionData[];
  theme?: 'rectangle';
}

export interface OptionData<T extends string = string> {
  display: string;
  value: T;
}

const RadioGroup = ({ name, options, currentValue, theme, ...props }: RadioGroupProps) => {
  return (
    <>
      {options.map(({ display, ...option }) => (
        <label key={option.value} className={classnames('radio', theme, option.value)}>
          <input type="radio" name={name} checked={option.value === currentValue} {...option} {...props} />
          <span className="radio__lb">{display}</span>
        </label>
      ))}
    </>
  );
};

export default RadioGroup;
