import { Location, useLocation, useNavigate } from 'react-router-dom';
import { ncmBridge } from '@/index';

export const useNavigateGo = () => {
  const navigate = useNavigate();
  return (link?: string) => navigate(link ?? '/');
};

export const useNavigateBack = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigateBack = () => {
    if ((location.state as any)?.isPrevPageBlocked || history.state?.idx === 0) {
      // WARNING: router v6에서 route stack 역할을 하는 값을 찾지 못했습니다.
      // 마이지에서의 웹뷰는 현재의 react app만 보여질 것이므로,
      // window.history를 이용해서 현재 페이지가 첫 진입 페이지인지를 확인하는 것으로 임시 처리하였습니다.
      // history.state.idx 값은 router v6의 navigate가 자체적으로 넣어주고 있는 것으로 확인되었습니다.
      // (참고 : 페이지별 deps가 매겨지고 deps 이동을 하도록 기획이 변경될 것 같습니다..)
      ncmBridge.goNativePage(-1);
      return;
    }
    navigate(-1);
  };
  return navigateBack;
};

export const useUrlQueryParam = (name: string, option?: { location?: Location; defaultValue?: string }) => {
  const { search } = option?.location ?? useLocation();
  const searchParams = new URLSearchParams(search);

  if (searchParams.has(name) && searchParams.get(name)) {
    return decodeURIComponent(searchParams.get(name) as string);
  }

  return option?.defaultValue ?? '';
};

export const useUrlQueryParams = (option?: { location?: Location }) => {
  const { search } = option?.location ?? useLocation();
  const urlSearchParams = new URLSearchParams(search);
  return Object.fromEntries(urlSearchParams.entries());
};
