export default Object.freeze({
  number: /[0-9]/g,
  notNumber: /[^0-9]/g,
  negativeNumber: /[0-9-]+/g,
  decimalNumber: /[0-9.]+/g,
  eng: /[a-zA-Z]/g,
  ko: /[ㄱ-힣]/g,
  koEng: /[a-zA-Z0-9ㄱ-힣]/g,
  currency: /[0-9,]/g,
  at: /@/g,
  noSpace: /\s/g, // 공백만 불가
  noCommonSpecial: /['"<>₩\\`‘”]/gi, // 공통 제한 문자 불가
  noPartSpecial: /['"‘”<>\\`(),:;@[\]\s]/g, // 일부 특수문자(‘”<>\`(),:;@[])와 공백 불가
  noSpecialSpace: /[^a-zA-Z0-9ㄱ-힣\s]/g, // 한글, 영문, 숫자, 공백만 가능
  noSpecial: /[^a-zA-Z0-9ㄱ-힣]/g, // 한글, 영문, 숫자만 가능
  possibleFullStopEmoji: /['"‘”<>\\`():;@#$^*[\]]/g, // 일부 특수문자(‘”<>\`():;@[]#$^*)와 공백 불가
  mobileNumber: /^(010|011|016|017|018|019)[0-9]{3,4}[0-9]{4}$/,
  /* eslint-disable */
  userid: /[^a-zA-Z0-9@\._\-]/g,
  passwordSpecial: /[!@#$%^&+=\-_.()]/g, // 특수문자 : ! @ # $ % ^ & + = - _ . ( ) 만 사용 가능
  emailId: /^[_A-Za-z0-9-\\+]+(.[_A-Za-z0-9-]+)$/,
  emailDomain: /^[A-Za-z0-9-]+(.[A-Za-z0-9]+)*(.[A-Za-z]{2,})$/,
  email: /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/,
  mobileNo: /(\d{11,12})/g,
  birthday: /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/,
  customsId: /^(p|P)[0-9]{11,13}$/, // 개인통관고유번호
  engNumber: /[^0-9a-zA-Z]/g, //영문, 숫자만 가능
  imageExtension: /.(bmp|png|jpg|jpeg|gif)$/i,
  guestPassword: /(.{4,20})/g,
  enclosedInSquareBrackets: /\[(.*?)\]/, // 대괄호로 감싸진 문자 찾기 (대괄호 포함)
  url: /(?:(https?):\/\/|www\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/igm, // http://, https://로 url이 시작한다면.
});
