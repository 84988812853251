import React, { useMemo } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';
import routes from '@/routes';
import classnames from 'classnames';
import { useNavigateBack } from '@/hooks/route';

interface HeaderProp {
  title?: string;
  hasPrevBtn?: boolean;
}

export default function Header({ title, hasPrevBtn = true }: HeaderProp) {
  const location = useLocation();
  const navigateBack = useNavigateBack();
  const match = matchRoutes(routes, location.pathname);
  // @ts-ignore
  const currentTitle = useMemo(() => match[1] && match[1].route.title, [location]);

  return (
    <header className={classnames('header', { 'header--fixed': window.scrollY !== 0 })}>
      <div className="header__inner">
        {hasPrevBtn && (
          <button type="button" className="ico-btn header__btn-left" onClick={() => navigateBack()}>
            <span className="a11y-hidden">이전 페이지 가기</span>
          </button>
        )}
        <h2 className="header__tit">{title ?? currentTitle}</h2>
      </div>
    </header>
  );
}
