import { CachingApiFunction, StorageKey } from '@/types/utils';
import { api } from '@/api/shophttpRequest';
import {
  GetBoardsConfigurationsResponse,
  Mall,
  MallsSslResponse,
  GetOrderConfigsResponse,
  GetPageScriptsRequest,
  GetPageScriptsResponse,
  GetTermsResponse,
} from '@shopby/shop-sdk';
import { ConfigStateType } from '@/types/context';
import { omit } from 'lodash-es';

const STORAGE_PREFIX = 'myz_';
const EXPIRATION_POSTFIX = '_expiration_date';
export const storageKeys = [
  // configs
  'mall',
  'categories',
  'skinGroup',
  'skin',
  'footerAbout',
  'boardsConfig',
  'ssl',
  'orderConfig',
  'pageScripts',
  // token
  'accessToken',
  'guestToken',
  'openIdProvider',
  'openIdToken',
  'nextUrl',
  // pages
  'recentlyKeyword',
  'accessNotIntroPath',
  'guestInfo',
  'cartInfo',
  'cartCount',
  'saveMemberId',
  'kcpAuth',
  'dormant',
  'recent',
  'popups',
  'invisibleToday',
  'scrollRestoration',
] as const;

export const storage = {
  set: (name: StorageKey, value: any, expire: number | null = null): void => {
    try {
      const data = JSON.stringify(value);
      window.localStorage.setItem(STORAGE_PREFIX + name, data);
      if (expire) {
        const expireUnixTime = (Date.now() + expire).toString();

        window.localStorage.setItem(STORAGE_PREFIX + name + EXPIRATION_POSTFIX, expireUnixTime);
      }
    } catch (err) {
      console.error('setStorage: Error setting key [' + name + '] in localStorage: ' + JSON.stringify(err));
    }
  },
  get: (name: StorageKey, checkExpire = true) => {
    if (!storageKeys.includes(name)) return null;

    try {
      const data = JSON.parse(<string>window.localStorage.getItem(STORAGE_PREFIX + name));
      if (checkExpire) {
        const expireUnixTime = Number(window.localStorage.getItem(STORAGE_PREFIX + name + EXPIRATION_POSTFIX));
        const expire = expireUnixTime > 0 && expireUnixTime < Date.now();
        if (expire) {
          window.localStorage.removeItem(STORAGE_PREFIX + name);
          window.localStorage.removeItem(STORAGE_PREFIX + name + EXPIRATION_POSTFIX);
        }
        return expire ? null : data;
      }
      return data;
    } catch (err) {
      console.error('getStorage: Error reading key [' + name + '] from localStorage: ' + JSON.stringify(err));
    }
  },
  remove: (name: StorageKey) => {
    try {
      window.localStorage.removeItem(STORAGE_PREFIX + name);
      window.localStorage.removeItem(STORAGE_PREFIX + name + EXPIRATION_POSTFIX);
    } catch (err) {
      console.error('removeStorage: Error removing key [' + name + '] from localStorage: ' + JSON.stringify(err));
    }
  },
};

async function fetchDataWithCaching<TResponse extends object, TParams = null>(
  api: CachingApiFunction<TResponse, TParams>,
  name: StorageKey,
): Promise<TResponse> {
  const { data } = await api();

  // WARN. 본 서비스 mall.categories 가 로컬스토리지 재한용량 5MB 를 초과하여 진행한 임시조치 이다.
  // 본 서비스에서는 mall.categories 를 스토리지에 저장한 후 사용하지 않기로 하였다.
  if (name === 'mall') {
    const mallData = omit(data, ['categories']);
    storage.set(name, mallData, 5 * 60 * 1000);
    return mallData as TResponse;
  }

  storage.set(name, data, 5 * 60 * 1000);
  return data;
}

export const cache: ConfigStateType = {
  get mall(): Mall {
    const exceptionalPaths = [
      '/mypage/info/cancel-lation-policy',
      '/mypage/info/withdrawal',
      '/mypage/info/license',
      '/mypage/info/event-promotion',
      '/mypage/info/privacy-agreement',
      '/mypage/info/privacy-policy',
      '/mypage/info/agreement',
    ]; //초기 api 호출 제외할 정책 컴포넌트 패스네임
    if (exceptionalPaths.includes(location.pathname)) return storage.get('mall', false);
    const dueData = storage.get('mall');
    return dueData ?? fetchDataWithCaching(api.admin.getMalls, 'mall');
  },
  get footerAbout(): GetTermsResponse {
    const dueData = storage.get('footerAbout');
    const request = {
      queryString: {
        termsTypes: 'MALL_INTRODUCTION,ACCESS_GUIDE',
      },
    };
    return dueData ?? fetchDataWithCaching(() => api.manage.getTerms(request), 'footerAbout');
  },
  get ssl(): MallsSslResponse[] {
    const dueData = storage.get('ssl');
    return dueData ?? fetchDataWithCaching(() => api.admin.getMallsSsl(), 'ssl');
  },
  get boardsConfig(): GetBoardsConfigurationsResponse {
    const dueData = storage.get('boardsConfig');
    return dueData ?? fetchDataWithCaching(api.manage.getBoardsConfig, 'boardsConfig');
  },
  get orderConfig(): GetOrderConfigsResponse {
    const dueData = storage.get('orderConfig');
    return dueData ?? fetchDataWithCaching(api.order.getOrderConfigs, 'orderConfig');
  },
  get pageScripts(): GetPageScriptsResponse[] {
    const dueData = storage.get('pageScripts');
    const request = {
      queryString: {
        pageTypes: 'MAIN, COMMON_HEAD, COMMON_FOOTER, PRODUCT, CART, ORDER, ORDER_COMPLETE',
      },
    };
    return (
      dueData ?? fetchDataWithCaching(() => api.manage.getPageScripts(<GetPageScriptsRequest>request), 'pageScripts')
    );
  },
};

export const setAccessToken = (accessToken?: string, expireSeconds?: number) => {
  storage.set('accessToken', accessToken, expireSeconds ? expireSeconds * 1000 : null);
};

export const getAccessToken = (checkExpire: boolean = true) => {
  return storage.get('accessToken', checkExpire) ?? '';
};

export const removeAccessToken = () => {
  storage.remove('accessToken');
  storage.remove('openIdToken');
  storage.remove('openIdProvider');
};

export const setGuestToken = (guestToken: string) => {
  return storage.set('guestToken', guestToken);
};

export const getGuestToken = () => {
  return storage.get('guestToken') ?? '';
};
