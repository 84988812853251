import React from 'react';
import { SvgIconProps } from './typings';

export default function IconLogo({ size = 50 }: SvgIconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 50 50" fill="none">
      <path
        d="M32.0312 1.677C22.5389 1.677 14.8438 9.48489 14.8438 19.1164V38.9339C14.8438 44.6253 19.3909 49.239 25 49.239C30.6091 49.239 35.1562 44.6253 35.1562 38.9339V22.2872C35.1562 20.536 33.7571 19.1164 32.0312 19.1164H28.9062V38.9339C28.9062 41.1229 27.1574 42.8974 25 42.8974C22.8426 42.8974 21.0938 41.1229 21.0938 38.9339V19.1164C21.0938 12.9873 25.9906 8.0186 32.0312 8.0186C38.0719 8.0186 42.9688 12.9873 42.9688 19.1164V48.4463H46.0938C47.8196 48.4463 49.2188 47.0267 49.2188 45.2755V19.1164C49.2188 9.48489 41.5236 1.677 32.0312 1.677Z"
        fill="white"
      />
      <path
        d="M21.9408 2.14504C20.6658 1.83897 19.3359 1.677 17.9688 1.677C8.47636 1.677 0.78125 9.48489 0.78125 19.1164V48.4463H3.03125C5.24039 48.4463 7.03125 46.6554 7.03125 44.4463V19.1164C7.03125 13.7806 10.7425 9.32428 15.6888 8.26007C17.2936 5.7779 19.433 3.68341 21.9408 2.14504Z"
        fill="white"
      />
    </svg>
  );
}
