import { sanitizeHTML } from '@/api/sanitize';
import { useGetProductQuery } from '@/hooks/product/queries';
import { Partner } from '@shopby/shop-sdk';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

export default function ProductDetailPartner() {
  // rawProductData.partner
  const { productNo } = useParams();
  const [partner, setPartner] = useState<Partner | null>(null);
  const { data: productDetailResponse, isLoading: isProductDetailDataLoading } = useGetProductQuery(Number(productNo));

  useEffect(() => {
    if (!isProductDetailDataLoading && productDetailResponse?.data.partner) {
      setPartner(productDetailResponse.data.partner);
    }
  }, [isProductDetailDataLoading]);

  return (
    <div className="modal-partner-info">
      <dl className="text-info">
        <div>
          <dt>상호명</dt>
          <dd>{partner?.companyName}</dd>
        </div>
        <div>
          <dt>대표자</dt>
          <dd>{partner?.ownerName}</dd>
        </div>
        <div>
          <dt>사업자등록번호</dt>
          <dd>{partner?.businessRegistrationNo}</dd>
        </div>
        <div>
          <dt>사업장소재지</dt>
          <dd dangerouslySetInnerHTML={{ __html: sanitizeHTML(partner?.officeAddressLabel ?? '') }}></dd>
        </div>
        <div>
          <dt>통신판매업번호</dt>
          <dd>{partner?.onlineMarketingBusinessDeclarationNo}</dd>
        </div>
        <div>
          <dt>고객센터</dt>
          <dd>{partner?.phoneNo}</dd>
        </div>
        <div>
          <dt>이메일</dt>
          <dd>{partner?.email}</dd>
        </div>
      </dl>
    </div>
  );
}
