import IconLogo from '@/components/common/icons/IconLogo';
import { generateId } from '@/utils/common';
import { BoardsBoardNoArticlesItem } from '@shopby/shop-sdk';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const MAX_TAG_DISPLAY_COUNT = 20;

type EventListItemProps = {
  article: BoardsBoardNoArticlesItem;
  isThumbnailUsed: boolean;
  isReplyUsed: boolean;
};

type TagItemProps = {
  tag: string;
};
function TagItem({ tag }: TagItemProps) {
  return <li className="tag-item">#{tag}</li>;
}

export default function EventArticleListItem({ article, isThumbnailUsed }: EventListItemProps) {
  const { articleNo, imageUrl, tags, title } = article;
  const navigate = useNavigate();
  const handleArticleClick = () => {
    navigate(`/mypage/info/event/${articleNo}`);
  };

  return (
    <div className="event-article-list-item">
      {isThumbnailUsed &&
        (imageUrl ? (
          <img src={imageUrl} className="article-thumbnail" onClick={handleArticleClick} />
        ) : (
          <div className="default-thumbnail" onClick={handleArticleClick}>
            <IconLogo />
          </div>
        ))}
      <h3 onClick={handleArticleClick}>{title}</h3>
      {tags.length > 0 && (
        <ul className="tag-list" onClick={handleArticleClick}>
          {tags.slice(0, MAX_TAG_DISPLAY_COUNT).map((tag) => (
            <TagItem key={generateId('tag')} tag={tag} />
          ))}
        </ul>
      )}
    </div>
  );
}
