import { ReasonType } from '@/pages/mypage/event/EventArticleReport';
import { getPlatform } from '@/utils/common';
import { getAccessToken } from '@/utils/storage';
import { GetBoardsBoardNoArticlesArticleNoResponse } from '@shopby/shop-sdk';
import qs from 'qs';

const BASE_URL = `${process.env.SHOP_API_URL}`;

type PathVariablesType = {
  boardNo: string;
  articleNo: string;
};

type GetBoardArticleRepliesRequest = {
  page: number;
  pageSize: number;
  includeRecommended?: boolean;
};

export type GetBoardArticleRepliesResponse = {
  items: GetBoardsBoardNoArticlesArticleNoResponse[];
  totalCount: number;
};

export const fetchBoardArticleReplies = async (
  pathVariables: PathVariablesType,
  param: Partial<GetBoardArticleRepliesRequest>,
) => {
  const { boardNo, articleNo } = pathVariables;
  const res = await fetch(`${BASE_URL}/boards/${boardNo}/articles/${articleNo}/replies?${qs.stringify(param)}`, {
    method: 'GET',
    headers: {
      clientId: process.env.CLIENT_ID ?? '',
      platform: getPlatform(),
      accessToken: getAccessToken(),
      version: '1.0',
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });

  return res.json() as Promise<GetBoardArticleRepliesResponse>;
};

export type PostBoardArticleRepliesRequest = {
  parentBoardArticleNo: number;
  articleContent: string;
};

export const defaultPostBoardArticleParams = {
  articleTitle: '제목없음',
  secreted: false,
};

// 게시글 댓글 작성하기
export const postBoardArticleReplies = async (boardNo: string, param: PostBoardArticleRepliesRequest) => {
  const res = await fetch(`${BASE_URL}/boards/${boardNo}/articles`, {
    method: 'POST',
    headers: {
      clientId: process.env.CLIENT_ID ?? '',
      platform: getPlatform(),
      accessToken: getAccessToken(),
      version: '1.0',
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...defaultPostBoardArticleParams, ...param }),
  });

  return res;
};

export type PostBoardArticleReportRequest = {
  content: string;
  reportReasonType: ReasonType;
};

export type ErrorResponseType = { status: number; error?: string; code?: string };

// 게시글 댓글 신고하기
export const postBoardArticleReport = async (
  pathVariables: PathVariablesType,
  param: PostBoardArticleReportRequest,
) => {
  const { boardNo, articleNo } = pathVariables;
  const res = await fetch(`${BASE_URL}/boards/${boardNo}/articles/${articleNo}/report`, {
    method: 'POST',
    headers: {
      clientId: process.env.CLIENT_ID ?? '',
      platform: getPlatform(),
      accessToken: getAccessToken(),
      version: '1.0',
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(param),
  });

  return res.ok ? res : (res.json() as Promise<ErrorResponseType>);
};
