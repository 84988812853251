import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { api } from '@/api/shophttpRequest';
import {
  DeleteProductsProductNoProductReviewsReviewNoRecommendRequest,
  PostProductsProductNoInquiresRequest,
  PostProductsProductNoProductReviewsRequest,
  PutProductsProductNoProductReviewsRequest,
  PostProductsProductNoProductReviewsReviewNoRecommendRequest,
  PostProductsProductNoProductReviewsReviewNoReport,
  PostProfileBlockedMembersRequest,
} from '@shopby/shop-sdk';
import { getLoginStatus } from '@/utils/auth';
import { ReviewOrderByType, ReviewOrderDirectionType } from '@shopby/shop-sdk/build/types/domain/display/unions';

export const useGetSpaceMappingQuery = (productNo: number) => {
  const loginStatus = getLoginStatus();
  return useQuery(
    ['space', 'mapping', productNo],
    () => api.display.getSpacesMapping({ queryString: { productNos: productNo.toString() } }),
    { enabled: loginStatus === 'LOGIN' },
  );
};

export const usePostSpaceMappingUnclassifiedMutation = (productNo: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    ['space', 'mapping', productNo],
    () => api.display.postSpacesMappingUnclassified({ requestBody: { productNos: [productNo] } }),
    {
      onSettled: () => queryClient.invalidateQueries(['space', 'mapping', productNo]),
    },
  );
};

export const useDeleteSpaceUnMappingMutation = (productNo: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    ['space', 'mapping', productNo],
    () =>
      api.display.deleteSpacesMapping({
        queryString: { productNos: productNo.toString() },
      }),
    {
      onSettled: () => queryClient.invalidateQueries(['space', 'mapping', productNo]),
    },
  );
};

export const usePostProductsProductNoInquiresMutation = (productNo: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    ['product', 'inquiry', productNo],
    ({
      type,
      title,
      content,
      secreted,
      email,
    }: Pick<
      PostProductsProductNoInquiresRequest['requestBody'],
      'type' | 'title' | 'content' | 'secreted' | 'email'
    >) =>
      api.display.postProductsProductNoInquires({
        pathVariable: {
          productNo,
        },
        requestBody: {
          type,
          title,
          email,
          secreted,
          content,
          productNo,
        },
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(['product', 'inquiries', 'count']); // 카운트 갱신
        queryClient.refetchQueries(['product', 'inquiries', productNo]);
      },
    },
  );
};

export const useGetProfileOrderOptionsProductReviewableWithInfinite = ({ pageSize }: { pageSize: number }) =>
  useInfiniteQuery('reviewable', ({ pageParam = 1 }) =>
    api.display.getProfileOrderOptionsProductReviewable({
      queryString: {
        pageNumber: pageParam,
        pageSize,
        hasTotalCount: true,
      },
    }),
  );

export const useGetProfileProductReviewsWithInfinite = ({ pageSize }: { pageSize: number }) =>
  useInfiniteQuery('review', ({ pageParam = 1 }) =>
    api.display.getProfileProductReviews({
      queryString: {
        pageNumber: pageParam,
        pageSize,
        hasTotalCount: true,
      },
    }),
  );

export const useGetProductsProductNoProductReviewsReviewNo = (productNo: string, reviewNo: string) => {
  return useQuery(['getProductsProductNoProductReviewsReviewNo', productNo, reviewNo], () =>
    api.display.getProductsProductNoProductReviewsReviewNo({
      pathVariable: {
        productNo,
        reviewNo,
      },
      useAccessToken: true,
    }),
  );
};

export const usePostProductsProductNoProductReviews = () =>
  useMutation('postProductsProductNoProductReviews', (request: PostProductsProductNoProductReviewsRequest) =>
    api.display.postProductsProductNoProductReviews(request),
  );

export const usePutProductsProductNoProductReviews = () =>
  useMutation('putProductsProductNoProductReviews', (request: PutProductsProductNoProductReviewsRequest) =>
    api.display.putProductsProductNoProductReviewsReviewNo(request),
  );

const REVIEW_PAGE_SIZE = 10;

export const useGetProductsProductNoProductReviewsWithInfinite = (
  productNo: number,
  reviewListType: ReviewOrderByType,
  reviewListDirectionType: ReviewOrderDirectionType,
) =>
  useInfiniteQuery(
    ['getProductsProductNoProductReviews', productNo, reviewListType, reviewListDirectionType],
    ({ pageParam = 1 }) =>
      api.display
        .getProductsProductNoProductReviews({
          pathVariable: {
            productNo,
          },
          queryString: {
            'order.by': reviewListType,
            'order.direction': reviewListDirectionType,
            bestReviewYn: 'N',
            pageNumber: pageParam,
            pageSize: REVIEW_PAGE_SIZE,
            hasTotalCount: true,
          },
        })
        .then(({ data }) => ({
          data,
          nextPage: pageParam + 1,
          isLast: 0 >= data.totalCount - REVIEW_PAGE_SIZE * pageParam,
        })),
    {
      getNextPageParam: (lastPage) => (lastPage.isLast ? undefined : lastPage.nextPage),
      select: (data) => ({ ...data, pages: data.pages.flatMap((page) => page.data.items) }),
    },
  );

export const useGetProductsProductNoProductReviews = (productNo: number) => {
  return useQuery(['getProductsProductNoProductReviews'], () =>
    api.display.getProductsProductNoProductReviews({
      pathVariable: {
        productNo,
      },
      queryString: {
        'order.by': 'RECOMMEND',
        'order.direction': 'DESC',
        bestReviewYn: 'N',
        pageNumber: 1,
        pageSize: 1,
        hasTotalCount: true,
      },
    }),
  );
};

export const useGetProductsProductNoPhotoReviews = (productNo: number) => {
  return useQuery(['GetProductsProductNoPhotoReviewsRequest'], () =>
    api.display.getProductsProductNoPhotoReviews({
      pathVariable: {
        productNo,
      },
      queryString: {
        pageNumber: 1,
        pageSize: 10,
        hasTotalCount: true,
      },
    }),
  );
};

const PHOTO_REVIEW_PAGE_SIZE = 10;

export const useGetProductsProductNoPhotoReviewsWithInfinite = (productNo: number) => {
  return useInfiniteQuery(
    ['GetProductsProductNoPhotoReviewsRequest', productNo],
    ({ pageParam = 1 }) =>
      api.display
        .getProductsProductNoPhotoReviews({
          pathVariable: {
            productNo,
          },
          queryString: {
            pageNumber: pageParam,
            pageSize: PHOTO_REVIEW_PAGE_SIZE,
            hasTotalCount: true,
          },
        })
        .then(({ data }) => ({
          data,
          nextPage: pageParam + 1,
          isLast: 0 >= data.totalCount - PHOTO_REVIEW_PAGE_SIZE * 1,
        })),
    {
      getNextPageParam: (lastPage) => (lastPage.isLast ? undefined : lastPage.nextPage),
      select: (data) => ({ ...data, pages: data.pages.flatMap((page) => page.data.contents) }),
    },
  );
};

export const usePostProductsProductNoProductReviewsReviewNoRecommend = () =>
  useMutation(
    'postProductsProductNoProductReviewsReviewNoRecommend',
    (request: PostProductsProductNoProductReviewsReviewNoRecommendRequest) =>
      api.display.postProductsProductNoProductReviewsReviewNoRecommend(request),
  );

export const useDeleteProductsProductNoProductReviewsReviewNoRecommend = () =>
  useMutation(
    'deleteProductsProductNoProductReviewsReviewNoRecommend',
    (request: DeleteProductsProductNoProductReviewsReviewNoRecommendRequest) =>
      api.display.deleteProductsProductNoProductReviewsReviewNoRecommend(request),
  );

export const usePostProductsProductNoProductReviewsReviewNoReport = () =>
  useMutation(
    'postProductsProductNoProductReviewsReviewNoReport',
    (request: PostProductsProductNoProductReviewsReviewNoReport) =>
      api.display.postProductsProductNoProductReviewsReviewNoReport(request),
    {
      onError: () => {
        alert('이미 신고가 완료된 리뷰에요.');
      },
    },
  );

export const usePostProfileBlockedMembers = () =>
  useMutation('postProfileBlockedMembers', (request: PostProfileBlockedMembersRequest) =>
    api.member.postProfileBlockedMembers(request),
  );

export const useGetProfileBlockedMembers = () => {
  return useQuery(['getProfileBlockedMembers'], () =>
    api.member.getProfileBlockedMembers({
      queryString: {
        pageNumber: 1,
        pageSize: 10,
        hasTotalCount: true,
      },
    }),
  );
};

export const useGetProfile = () => {
  return useQuery(['getProfile'], () => api.member.getProfile());
};
