import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { useNavigateBack } from '@/hooks/route';

/**
 * 모바일 개발파트에서 제공한 ncm-bridge.js 코드를 싱글톤 형태로 리팩토링
 * TODO: 모바일 개발파트와 소통하여 타입 및 불필요 함수 정리 필요
 * TODO: 전체적인 로직이 이게 최선인지 확인 필요. (모바일 쪽 브릿지 동작 확인이 필요)
 * TODO: 모바일 개발파트에서 삽입한 console.log들 지워도 되는지 확인 필요
 */

type GoNativePageLocationType = 'MySpace' | 'Discover' | 'MyPage' | 'Exhibition' | -1;
type CallHandler = (handlerName: string, data: any, responseCallback?: Function) => void;
type UseAppBackBtnHandlerOption = UseAppBackBtnHandlerOptionA | UseAppBackBtnHandlerOptionB;

interface UseAppBackBtnHandlerOptionA {
  callback: () => void;
  nextUrl?: never;
}
interface UseAppBackBtnHandlerOptionB {
  nextUrl: `/${string}`;
  callback?: never;
}

export type ShowNativeCommentParam = null | {
  topCommentNo?: number;
  isWriteMode?: boolean;
};

interface NCMBridgeInterface {
  goNativePage: (location: GoNativePageLocationType) => void;
  fetchProductLikeStatusToNative: (isLiked: boolean) => void;
  fetchProductOrderToNative: () => void;
  fetchSnackbarToNative: (isMessage: string, isBtn?: string, isLocation?: string) => void;
  showNativeProductUnLikePopup: () => Promise<boolean>;
  showNativeMemoPopup: () => Promise<boolean>;
  showNativeLoginPopup: () => Promise<boolean>;
  expireNativeCacheAndGoMain: () => void;
  useAppBackBtnHandler: (option?: UseAppBackBtnHandlerOption) => void;
  showNativeComment: (param: ShowNativeCommentParam) => Promise<boolean>;
  fetchRecentProductToNative: (productNo: number) => void;
  refreshProductList: () => void;
  nativeShare: (param: { title: string; url: string }) => void;
}

export const NCMBridge = (() => {
  let instance: NCMBridgeInterface;
  let sendMessageQueue: { [key: string]: any }[] = [];
  let uniqueId = 1;
  const messageHandlers: { [key: string]: Function } = {};
  const responseCallbacks: { [key: string]: Function } = {};

  const init = () => {
    setEnvironment();
    window.bridge = initBridge((bridge: any) => bridge);

    return {
      goNativePage: (location: GoNativePageLocationType) => {
        if (location === -1) {
          window.bridge.callHandler('popUpTo', (response: any) =>
            console.log('ncmBridge [popUpTo] response::', response),
          );
          return;
        }
        window.bridge.callHandler('popUpTo', { location }, (response: any) =>
          console.log('ncmBridge [popUpTo] response::', response),
        );
      },
      fetchProductLikeStatusToNative: (isLiked: boolean) => {
        window.bridge.callHandler('productLike', { liked: isLiked }, (response: any) =>
          console.log('ncmBridge [productLike] response::', response),
        );
      },
      fetchProductOrderToNative: () => {
        window.bridge.callHandler('showOrder', (response: any) =>
          console.log('ncmBridge [showOrder] response::', response),
        );
      },
      fetchSnackbarToNative: (isMessage: string, isBtn?: string, isLocation?: string) => {
        window.bridge.callHandler(
          'showSnackbar',
          { message: isMessage, btn: isBtn, location: isLocation },
          (response: any) => console.log('ncmBridge [showSnackbar] response::', response),
        );
      },
      showNativeProductUnLikePopup: (): Promise<boolean> =>
        new Promise((resolve) =>
          window.bridge.callHandler('productUnLike', ({ Unliked: isUnLiked }: { Unliked: boolean }) => {
            console.log('ncmBridge [productUnLike] response::', isUnLiked);
            resolve(isUnLiked);
          }),
        ),
      showNativeMemoPopup: (): Promise<boolean> =>
        new Promise((resolve) =>
          window.bridge.callHandler('showMemo', ({ isMemoChanged: isMemoChange }: { isMemoChanged: boolean }) => {
            console.log('ncmBridge [showMemo] response::', isMemoChange);
            resolve(isMemoChange);
          }),
        ),
      showNativeLoginPopup: (): Promise<boolean> =>
        new Promise((resolve) =>
          window.bridge.callHandler('showLogin', ({ isLogin: isLoginSuccess }: { isLogin: boolean }) => {
            console.log('ncmBridge [showLogin] response::', isLoginSuccess);
            resolve(isLoginSuccess);
          }),
        ),
      expireNativeCacheAndGoMain: () => {
        window.bridge.callHandler('expiredToken', (response: any) => {
          console.log('ncmBridge [expiredToken] response::', response);
        });
      },
      useAppBackBtnHandler: (option?: UseAppBackBtnHandlerOption) => {
        /**
         * < 디바이스 자체 뒤로가기 이벤트 > 를 커스텀 핸들하기 위한 커스텀 훅입니다.
         * window.bridge.onGoBackInApp 에 핸들러를 등록하며, 이 핸들러를 앱에서 뒤로가기 이벤트 핸들러로 사용하게 됩니다.
         *
         * 브릿지 사용없이 onpopstate를 활용하여 핸들시도 하였으나,
         * 웹에서 없는 이슈가 앱에서 발생하여 위와 같은 로직을 구현하였습니다.
         *
         * @param { callback, nextUrl }
         * callback : 디바이스 뒤로가기 이벤트가 발생하면 실행할 함수입니다.
         * nextUrl: 이 값을 넣게 되면, 해당 url로 바로 리다이렉팅하며, useLocation().state: { isPrevPageBlocked: true } 를 설정해줍니다.
         *
         * { isPrevPageBlock: true } 가 설정된 페이지에서는, 디바이스 뒤로가기 이벤트 발생 시 앱 화면으로 넘어갑니다.
         * -> 다만 앱 이슈 존재하여, 앱 화면으로 넘어가려면 해당 페이지 컴포넌트에도 useAppBackBtnHandler()를 설정해주어야 합니다. (설정해주지 않아도 되도록 설계하였으나..)
         *
         * App.tsx에서 useAppBackBtnHandler()를 실행하므로써 디폴트 핸들러가 미리 설정됩니다.
         */
        const callback = option?.callback;
        const nextUrl = option?.nextUrl;
        const navigate = useNavigate();
        const navigateBack = useNavigateBack();

        const defaultGoBackInAppHandler = useCallback(() => {
          console.log('onGoBackInApp start');
          navigateBack();
        }, []);

        const handleGoBackInApp = useCallback(() => {
          console.log('onGoBackInApp start');
          if (nextUrl) {
            navigate(nextUrl, { state: { isPrevPageBlocked: true } });
            return;
          }
          callback?.();
        }, [callback, nextUrl]);

        useEffect(() => {
          if (!option) {
            window.bridge.onGoBackInApp = defaultGoBackInAppHandler;
            return;
          }
          window.bridge.onGoBackInApp = handleGoBackInApp;
          return () => {
            window.bridge.onGoBackInApp = defaultGoBackInAppHandler;
          };
        }, [option?.callback, option?.nextUrl]);
      },
      showNativeComment: (param: ShowNativeCommentParam = null): Promise<boolean> =>
        new Promise((resolve) =>
          window.bridge.callHandler('showComment', param, ({ refreshComment }: { refreshComment: boolean }) => {
            console.log('ncmBridge [showComment] response::', refreshComment);
            resolve(refreshComment);
          }),
        ),
      fetchRecentProductToNative: (productNo: number) => {
        window.bridge.callHandler('onProductDetail', { productNo }, (response: any) => {
          console.log('ncmBridge [onProductDetail] response::', response);
        });
      },
      refreshProductList: () => {
        window.bridge.callHandler('refreshProductList', (response: any) =>
          console.log('ncmBridge [refreshProductsList] response::', response),
        );
      },
      nativeShare: (param: { title: string; url: string }) => {
        window.bridge.callHandler('nativeShare', param, (response: any) =>
          console.log('ncmBridge [nativeShare] response::', response),
        );
      },
    };
  };

  const setEnvironment = () => {
    console.log('NCMBridge:');
    if (window.NCMBridge) {
      return;
    }
    if (!window.onerror) {
      window.onerror = (msg, url, line) => {
        console.log('NCMBridge: ERROR:' + msg + '@' + url + ':' + line);
      };
    }

    window.NCMBridge = {
      init: initBridge,
      platform: getPlatform,
      registerHandler,
      callHandler,
      _fetchQueue: fetchQueue,
      _handleMessage: handleMessage,
    };
  };

  const initBridge = (callback: Function) => {
    if (window.NCMBridge) {
      return callback(window.NCMBridge);
    }
    if (window.NCMBridgeCallbacks) {
      return window.NCMBridgeCallbacks.push(callback);
    }
    window.NCMBridgeCallbacks = [callback];

    const _callNCMBridgeCallbacks = () => {
      const callbacks = window.NCMBridgeCallbacks;
      delete window.NCMBridgeCallbacks;

      if (callbacks) {
        callbacks.forEach((callback) => callback(window.NCMBridge));
      }
    };
    setTimeout(_callNCMBridgeCallbacks, 0);
  };

  const checkMobileOS = {
    Android: function () {
      return navigator.userAgent.match(/Android/i) !== null;
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null;
    },
  } as const;

  const getPlatform = (): 'aos' | 'ios' | 'web' => {
    if (checkMobileOS.Android()) {
      return 'aos';
    } else if (checkMobileOS.iOS()) {
      return 'ios';
    } else {
      return 'web';
    }
  };

  const registerHandler = (handlerName: string, handler: Function) => {
    messageHandlers[handlerName] = handler;
  };

  const callHandler: CallHandler = (...args) => {
    let [handlerName, data, responseCallback] = args;
    if (args.length === 2 && typeof data === 'function') {
      // TODO: data를 세 번째 인자로 하는게 낫지 않을지
      responseCallback = data;
      data = null;
    }
    doSend({ handlerName, data }, responseCallback);
  };

  const increaseUniqueId = () => {
    uniqueId++;
  };

  const doSend = (message: { [key: string]: any }, responseCallback?: Function) => {
    if (responseCallback) {
      const callbackID = `id_${uniqueId}_${new Date().getTime()}`;
      increaseUniqueId();
      responseCallbacks[callbackID] = responseCallback;
      message['callbackID'] = callbackID;
    }
    sendMessageQueue.push(message);

    const platform = getPlatform();
    if (platform === 'ios') {
      window.webkit.messageHandlers.IosInterface.postMessage(null);
    } else if (platform === 'aos') {
      window.AndroidInterface?.postMessage('null'); // TODO: 얘는 왜 null 스트링으로 보내야할까?
    }
  };

  const fetchQueue = () => {
    const messageQueueString = JSON.stringify(sendMessageQueue);
    sendMessageQueue = [];
    return messageQueueString;
  };

  const dispatchMessage = (messageJSON: string) => {
    const message = JSON.parse(messageJSON);
    let responseCallback;

    if (message.responseID) {
      responseCallback = responseCallbacks[message.responseID];
      if (!responseCallback) {
        return;
      }
      responseCallback(message.responseData);
      delete responseCallbacks[message.responseID];
    } else {
      console.log('NCMBridge: callbackID:', message.callbackID);
      if (message.callbackID) {
        const callbackResponseId = message.callbackID;
        responseCallback = (responseData: any) => {
          doSend({
            handlerName: message.handlerName,
            responseID: callbackResponseId,
            responseData: responseData,
          });
        };
      }

      const handler = messageHandlers[message.handlerName];
      if (!handler) {
        console.log('NCMBridge: WARNING: no handler for message from iOS:', message);
      } else {
        console.log('NCMBridge: message:', message);
        handler(message.data, responseCallback);
      }
    }
  };

  const handleMessage = (messageJSON: string) => {
    dispatchMessage(messageJSON);
  };

  return {
    getInstance: () => {
      if (instance) return instance;
      instance = init();
      return instance;
    },
  };
})();
