import { sanitizeHTML } from '@/api/sanitize';
import { useProductClaimGuide } from '@/hooks/product/useProductClaimGuide';
import React from 'react';
import { useParams } from 'react-router-dom';

export default function ProductDetailClaimGuide() {
  const { productNo } = useParams();
  const { guide, defaultGuide } = useProductClaimGuide(Number(productNo));

  return <div className="content-wrap" dangerouslySetInnerHTML={{ __html: sanitizeHTML(guide || defaultGuide) }}></div>;
}
