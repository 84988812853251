import React, { createContext, useContext, useEffect, useState } from 'react';
import { ConfigStateType } from '@/types/context';
import { cache } from '@/utils/storage';

const initialState: ConfigStateType = {
  mall: null,
  footerAbout: null,
  boardsConfig: null,
  ssl: null,
  orderConfig: null,
  pageScripts: null,
};

const ConfigState = createContext<ConfigStateType>(initialState);

export const useConfigState = () => useContext(ConfigState);

export function ConfigProvider({ children }: { children: React.ReactNode }) {
  const [configData, setConfigData] = useState(initialState);

  const getAllConfigData = async () => {
    const [mall, footerAbout, boardsConfig, ssl, orderConfig, pageScripts] = await Promise.all([
      cache.mall,
      cache.footerAbout,
      cache.boardsConfig,
      cache.ssl,
      cache.orderConfig,
      cache.pageScripts,
    ]);
    setConfigData({ mall, footerAbout, boardsConfig, ssl, orderConfig, pageScripts });
  };

  useEffect(() => {
    getAllConfigData();
  }, []);

  return <ConfigState.Provider value={configData}>{children}</ConfigState.Provider>;
}
