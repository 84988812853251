import React, { useRef } from 'react';
import classnames from 'classnames';
import { api } from '@/api/shophttpRequest';
import { setAccessToken } from '@/utils/storage';
import { useNavigateGo } from '@/hooks/route';

const LoginForm = () => {
  const idRef = useRef<HTMLInputElement>(null);
  const pwRef = useRef<HTMLInputElement>(null);
  const navigateGoRoot = useNavigateGo();

  const fetchOauthToken = () =>
    api.auth
      .postOauthToken({
        requestBody: {
          memberId: idRef?.current?.value ?? '',
          password: pwRef?.current?.value ?? '',
        },
      })
      .then(({ data }) => {
        setAccessToken(data.accessToken, data.expireIn);
        alert('success');
        navigateGoRoot();
      })
      .catch(() => alert('Bad Request'));

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          fetchOauthToken();
        }}
        className={classnames('login-form')}
      >
        <p className={classnames('login-form__field')}>
          <input type="text" placeholder="아이디" id={'id'} name={'id'} ref={idRef} />
        </p>
        <p className={classnames('login-form__field')}>
          <input type="text" placeholder="비밀번호" id={'password'} name={'password'} ref={pwRef} />
        </p>
        <button type="submit">로그인</button>
      </form>
    </>
  );
};

export default LoginForm;
