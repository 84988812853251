import React, { MouseEventHandler } from 'react';

interface InputBtnGroupProps {
  items?: Array<'CANCEL' | 'CONFIRM'>;
  cancelTxt?: '취소' | '이전';
  confirmTxt?: string;
  isConfirmDisabled?: boolean;
  onConfirmBtnClick?: MouseEventHandler<HTMLButtonElement>;
  onCancelBtnClick?: MouseEventHandler<HTMLButtonElement>;
}

function InputBtnGroup({
  items = ['CANCEL', 'CONFIRM'],
  confirmTxt = '완료',
  cancelTxt = '취소',
  isConfirmDisabled = false,
  onConfirmBtnClick,
  onCancelBtnClick,
}: InputBtnGroupProps) {
  return (
    <div className="btn-group btn-group--input">
      {items.includes('CANCEL') && (
        <button className="btn btn--lg btn--gray btn--round" onClick={onCancelBtnClick} type="button">
          {cancelTxt}
        </button>
      )}
      {items.includes('CONFIRM') && (
        <button
          className="btn btn--lg btn--purple btn--round"
          type="submit"
          disabled={isConfirmDisabled}
          onClick={onConfirmBtnClick}
        >
          {confirmTxt}
        </button>
      )}
    </div>
  );
}

export default InputBtnGroup;
