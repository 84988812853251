import { useQuery, useInfiniteQuery } from 'react-query';
import { api } from '@/api/shophttpRequest';
import { GetBoardsBoardNoArticlesArticleNoRequest } from '@shopby/shop-sdk';

export const BOARD_PAGE_SIZE = 10;

export const useGetBoardsBoardNoArticlesInfiniteQuery = (boardNo: string, categoryNo?: number) =>
  useInfiniteQuery(
    ['boards', boardNo, categoryNo],
    ({ pageParam = 1 }) => {
      const baseParam = {
        pageNumber: pageParam,
        pageSize: BOARD_PAGE_SIZE,
        hasTotalCount: true,
      };
      const queryString = categoryNo
        ? {
            ...baseParam,
            categoryNo,
          }
        : baseParam;

      return api.manage
        .getBoardsBoardNoArticles({
          pathVariable: {
            boardNo: boardNo,
          },
          queryString,
        })
        .then(({ data }) => ({
          data,
          nextPage: pageParam + 1,
          isLast: 0 >= data.totalCount - BOARD_PAGE_SIZE * pageParam,
        }));
    },
    {
      getNextPageParam: (lastPage) => (lastPage.isLast ? undefined : lastPage.nextPage),
      select: (data) => ({ ...data, pages: data.pages.flatMap((page) => page.data.items) }),
    },
  );

export const useGetBoardsBoardNoArticlesArticleNoQuery = (request: GetBoardsBoardNoArticlesArticleNoRequest) =>
  useQuery(['boardDetail'], () => api.manage.getBoardsBoardNoArticlesArticleNo(request), {
    notifyOnChangeProps: ['data'],
    enabled: !!request.pathVariable.articleNo && !!request.pathVariable.boardNo,
  });

// 청약철회
const defaultRefundBoardContent = {
  alpha: {
    boardNo: '9046',
    articleNo: '62622',
  },
  real: {
    boardNo: '183745',
    articleNo: '28104',
  },
} as const;

export const useGetDefaultRefundContentQuery = () =>
  useQuery(['board'], () =>
    api.manage.getBoardsBoardNoArticlesArticleNo({
      pathVariable: {
        ...defaultRefundBoardContent[process.env.BUILD_ENV],
      },
      useAccessToken: false,
    }),
  );

// 탈퇴안내
const defaultWithdrawalBoardContent = {
  alpha: {
    boardNo: '9367',
    articleNo: '202454',
  },
  real: {
    boardNo: '188445',
    articleNo: '30418',
  },
} as const;

export const useGetDefaultWithdrawalContentQuery = () =>
  useQuery(['board'], () =>
    api.manage.getBoardsBoardNoArticlesArticleNo({
      pathVariable: {
        ...defaultWithdrawalBoardContent[process.env.BUILD_ENV],
      },
      useAccessToken: false,
    }),
  );
