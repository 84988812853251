import React, { ChangeEventHandler, InputHTMLAttributes } from 'react';
import classnames from 'classnames';

export type OptionType = 'require' | 'option';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  theme?: 'dark';
  txt?: string;
  optionType?: OptionType;
  isChecked?: boolean;
  onCheckboxInputChange?: ChangeEventHandler<HTMLInputElement>;
}

function Checkbox({ theme, txt, optionType, isChecked, onCheckboxInputChange, ...props }: CheckboxProps) {
  const transfer = (option: OptionType): string => (option === 'require' ? '(필수)' : '(선택)');
  const classNameType = classnames(optionType && `checkbox__${optionType}`);

  return (
    <label className={classnames('checkbox', theme)}>
      <input type="checkbox" {...props} onChange={onCheckboxInputChange} checked={isChecked} readOnly />
      <span className="checkbox__lb">
        <span className={classNameType}>{optionType && transfer(optionType)}</span>
        <span className="checkbox__txt">{txt}</span>
      </span>
    </label>
  );
}

export default Checkbox;
