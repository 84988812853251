import MobileDetect from 'mobile-detect';

export const resizeImage = (img: string, size: number) => {
  return `${img}?${size}x${size}`;
};

export const addScript = (src: string) => {
  const script = document.createElement('script');
  script.src = src;
  script.async = true;

  document.body.appendChild(script);
};

export const generateRandomString = (length = 6) => {
  const availableChar = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  return Array.from(Array(length).keys()).reduce(
    (prev) => prev + availableChar.charAt(Math.floor(Math.random() * availableChar.length)),
    '',
  );
};

export const getUnitDigitStr = (num: number): string => {
  return num < 10 ? `0${num}` : `${num}`;
};

/**
 * locales는 아래와 같다
 * 'en-US',    // United States
 * 'ko-KR',    // korea
 * undefined,   // 브라우저에게 locaels를 맡긴다.
 *
 * 천단위마다 콤마 찍기 (10000 -> 10,000)
 */
export const toCurrencyString = (num = 0): string => {
  // return String(num).replace(/(.)(?=(\d{3})+$)/g, '$1,'); 정규식을 이용하는 방법도 있다.
  return num.toLocaleString(undefined, { minimumFractionDigits: 0 });
};

export const saveToClipboard = (value: string, successMsg = '클립보드에 저장되었습니다.') => {
  navigator.clipboard.writeText(value).then(() => {
    alert(successMsg);
  });
};

type Platform = 'AOS' | 'IOS' | 'MOBILE_WEB';
export const getPlatform = (): Platform => {
  const userAgent = navigator.userAgent;
  const platformInfo = new MobileDetect(userAgent);
  const os = platformInfo.os();

  switch (os) {
    case 'AndroidOS':
      return 'AOS';
    case 'iOS':
      return 'IOS';
    default:
      return 'MOBILE_WEB';
  }
};

export const convertToArray = (value: any) => {
  return Array.isArray(value) ? value : [value];
};

export const getAndroidStatusBarHeight = () => {
  return window.localStorage.getItem('statusBarHeight');
};

export const isFromNativeApp = () => {
  return !!window.localStorage.getItem('appPackage');
};

export const androidStatusBarHeight = () => {
  const root = document.querySelector<HTMLElement>(':root');
  let aosHeight: string | null | number = getAndroidStatusBarHeight();

  aosHeight = aosHeight && Number(aosHeight.replace(/"/g, ''));

  if (aosHeight === null) {
    aosHeight = 0;
  }

  root && root.style.setProperty('--aos-header-height', `${aosHeight}px`);
};

export const generateId = (name = '', size = 18, noNumber = true) => {
  const id = crypto.getRandomValues(new Uint8Array(size)).reduce((acc, curr) => {
    curr &= 63;
    acc += curr < 36 ? curr.toString(36) : curr < 62 ? (curr - 26).toString(36).toUpperCase() : curr > 62 ? '-' : '_';

    return acc;
  }, '');

  const idName = `${name ? `${name}-` : ''}${id}`;

  return noNumber ? idName : idName.replace(/[0-9]/g, '');
};
