import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import '@/assets/scss/style.scss';
import App from '@/App';
import { createRoot } from 'react-dom/client';
import { NCMBridge } from '@/utils/ncmBridge';
import { getPlatform } from '@/utils/common';

(function init() {
  if (getPlatform() === 'AOS') {
    document.documentElement.classList.add('android');
  }
})();

const container = document.getElementById('root');
const root = createRoot(container!);
const app = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
root.render(app);
export const ncmBridge = NCMBridge.getInstance();
