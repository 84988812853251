import React from 'react';
import classnames from 'classnames';
import message from '@/const/message';
import { MessageType } from '@/types/constants';

interface InputRowProps {
  invalidTextType?: MessageType | '';
  invalid?: boolean;
  emailType?: boolean;
}

const InputRow: React.FC<InputRowProps> = ({ children, invalidTextType = '', invalid = false, emailType = false }) => {
  return (
    <p className={classnames('join-form__row', { invalid, 'join-form__email': emailType })}>
      {children}
      {invalidTextType.length > 0 && (
        <em className={classnames('invalid-txt')}>{message[invalidTextType as MessageType]}</em>
      )}
    </p>
  );
};

export default InputRow;
