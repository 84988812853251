import React from 'react';
import Icon from '@/components/common/Icon';
import IconSiren from '@/components/common/icons/IconSiren';
import classNames from 'classnames';
import { GetBoardsBoardNoArticlesArticleNoResponse } from '@shopby/shop-sdk';
import { getYmd } from '@/utils/dateFormat';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { api } from '@/api/shophttpRequest';
import { useModal, useModalState } from '@/context/modal.context';
import { getLoginStatus } from '@/utils/auth';
import { ncmBridge } from '@/index';

enum displayStatus {
  DISPLAY = 'DISPLAY',
  BLIND = 'BLIND',
}

const MAX_REPORT_COUNT = 10;

export interface ReplyCommentType extends GetBoardsBoardNoArticlesArticleNoResponse {
  reportCount: number;
  displayStatusType: displayStatus;
}

type ReplyCommentItemProps = {
  replyComment: ReplyCommentType;
  boardNo: string;
  deleteReplyCommentItem: (articleNo: number) => void;
};

export function ReplyCommentItem({ replyComment, boardNo, deleteReplyCommentItem }: ReplyCommentItemProps) {
  const { articleNo, content, memberId, registerNo, registerYmdt, modifiable, displayStatusType, reportCount } =
    replyComment;

  const navigate = useNavigate();

  const openModal = useModal();
  const { handleClose } = useModalState();

  const { mutate: deleteArticle, isLoading: isDeleteLoading } = useMutation(
    ['deleteEventArticle'],
    () =>
      api.manage.deleteBoardsBoardNoArticlesArticleNo({
        pathVariable: {
          boardNo,
          articleNo: articleNo.toString(),
        },
        useAccessToken: true,
      }),
    {
      onSuccess: (res) => {
        if (res.status === 204) {
          deleteReplyCommentItem(articleNo);
          ncmBridge.fetchSnackbarToNative('댓글이 삭제되었어요.');
        }
      },
      onError: (err) => {
        console.error('[onError]', err);
      },
    },
  );

  const handleDeleteReplyArticle = () => {
    openModal({
      title: '댓글 삭제',
      type: 'confirm',
      mode: 'order',
      isOnlyConfirm: true,
      description: '댓글을 삭제하시겠어요?\n삭제한 댓글은 되돌릴 수 없어요.',
      confirmBtnLabel: '삭제',
      onConfirm: () => {
        deleteArticle();
        handleClose();
      },
    });
  };

  const navigateReportEventReply = () => {
    navigate(`/mypage/info/event/${articleNo}/report`, {
      state: { boardNo, articleNo, registerNo },
    });
  };

  const handleReportBlame = () => {
    const loggedIn = getLoginStatus() === 'LOGIN';

    if (!loggedIn) {
      ncmBridge.showNativeLoginPopup().then((success: boolean) => {
        if (success) {
          navigateReportEventReply();
        }
      });

      return;
    }

    navigateReportEventReply();
  };

  return displayStatusType === displayStatus.BLIND || reportCount >= MAX_REPORT_COUNT ? (
    <li className="reply-list-item__blind-message">
      <Icon type="info" />
      블라인드 처리된 댓글이에요.
    </li>
  ) : (
    <li className={classNames('reply-list-item', modifiable && 'isMine')}>
      <div className="top">
        <span className="article-sub-text">{getYmd(registerYmdt)}</span>
        <span className="article-sub-text name">{memberId.split('@')[0]}</span>

        {modifiable && (
          <div className="btn-wrapper">
            <button
              type="button"
              className="article-sub-text delete-btn"
              onClick={handleDeleteReplyArticle}
              disabled={isDeleteLoading}
            >
              삭제
            </button>
          </div>
        )}
      </div>

      <p className="reply-list-item-content">{content}</p>

      <div className="reply-list-item-bottom">
        <button type="button" onClick={handleReportBlame}>
          <IconSiren className="siren-icon" />
        </button>
      </div>
    </li>
  );
}
