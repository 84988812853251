import React from 'react';
import classnames from 'classnames';
import { ModalOptions } from '@/types/context';
import { onEnter } from '@/utils/event';
import { ncmBridge } from '@/index';

import 'swiper/scss';
import { useModal } from '@/context/modal.context';

interface ModalProp extends ModalOptions {
  theme?: 'sm' | 'full';
  mode?: 'order' | 'review' | 'common';
  open: boolean;
  isOnlyConfirm?: boolean;
  confirmBtnLabel?: string;
  onSubmit: () => void;
  onClose: () => void;
  onDimmedClick?: () => void;
}

const ModalTemplate: React.FC<ModalProp> = ({
  theme = 'sm',
  mode = 'common',
  children,
  open,
  title,
  type = 'alert',
  description,
  wrapperClassName,
  headerClassName,
  isOnlyConfirm = false,
  confirmBtnLabel = '확인',
  onSubmit,
  onClose,
}) => {
  ncmBridge.useAppBackBtnHandler({ callback: onClose });
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (!onEnter(event.code)) return;
    event.preventDefault();
    onSubmit();
  };

  const openModal = useModal();

  const handleModalBtnCancle = () => {
    if (mode === 'order') {
      openModal({
        title: '주문서 작성 취소',
        mode: 'order',
        type: 'confirm',
        isOnlyConfirm: true,
        description: '주문서 작성을 취소하시겠어요?\n내용은 저장되지 않아요.',
        confirmBtnLabel: '삭제',
        onConfirm: () => {
          onClose();
        },
      });
    } else {
      onClose();
    }
  };

  return (
    <>
      {open && (
        <div className={classnames('modal', `modal--${theme}`, wrapperClassName, mode === 'order' && 'order')}>
          {title &&
            (theme === 'full' ? (
              <header>
                <h2 className={classnames('modal__tit', headerClassName)} onClick={handleModalBtnCancle}>
                  {title}
                </h2>
              </header>
            ) : (
              <header>
                <h2 className={classnames('modal__tit', headerClassName)}>{title}</h2>
              </header>
            ))}
          <section className="modal__inner">
            {description ? <p className="modal__desc">{description}</p> : children}
          </section>
          <div className="modal-btn-group">
            {description && type === 'confirm' && (
              <button type="button" onClick={() => onClose()}>
                취소
              </button>
            )}
            {mode === 'review' && (
              <button type="button" onClick={() => onClose()}>
                취소
              </button>
            )}
            {(description || isOnlyConfirm) && (
              <button
                type="button"
                autoFocus={true}
                onKeyPress={handleKeyPress}
                onClick={() => onSubmit()}
                className={classnames('btn-confirm', mode === 'order' && 'order')}
              >
                {confirmBtnLabel}
              </button>
            )}
          </div>
          {!isOnlyConfirm &&
            (mode === 'order' ? (
              <button onClick={handleModalBtnCancle} type="button" className="ico-btn modal__cancel">
                <span className="a11y-hidden">모달 닫기</span>
              </button>
            ) : (
              <button onClick={() => onClose()} type="button" className="ico-btn modal__cancel">
                <span className="a11y-hidden">모달 닫기</span>
              </button>
            ))}
        </div>
      )}
      <div className="dimmed" onClick={() => onClose()}>
        <span className="a11y-hidden">모달 닫기</span>
      </div>
    </>
  );
};

export default ModalTemplate;
