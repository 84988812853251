import React from 'react';
import classnames from 'classnames';

interface LabelProps {
  lbFor?: string;
  txt: string;
  required?: boolean;
}

const Label: React.FC<LabelProps> = ({ lbFor, txt, required = false }) => {
  const labelClass = classnames('label', { 'label--required': required });
  return (
    <label className={labelClass} htmlFor={lbFor}>
      {txt}
    </label>
  );
};

export default Label;
