import EventArticleDetailView from '@/components/mypage/event/EventArticleDetailView';
import { useConfigState } from '@/context/config.context';
import { useGetBoardsBoardNoArticlesArticleNoQuery } from '@/hooks/board/queries/board';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { EVENT_BOARD_ID } from './Event';

function useEventBoard() {
  const { boardsConfig } = useConfigState();
  const { articleNo = '' } = useParams();

  const eventBoardConfig = useMemo(() => {
    const eventBoard = boardsConfig?.boardConfigs.find(({ boardId }) => boardId === EVENT_BOARD_ID);

    return eventBoard ?? null;
  }, [boardsConfig]);

  const boardNo = eventBoardConfig?.boardNo.toString() ?? '';

  const { data: article } = useGetBoardsBoardNoArticlesArticleNoQuery({
    pathVariable: {
      boardNo,
      articleNo,
    },
    queryString: {
      withReplied: false,
    },
    useAccessToken: false,
  });

  return {
    isReplyUsed: eventBoardConfig?.replyUsed ?? false,
    article: article?.data,
    boardNo,
    articleNo,
  };
}

export default function EventArticleDetail({ popupMode = false }) {
  const { article, isReplyUsed, boardNo, articleNo } = useEventBoard();

  return article ? (
    <EventArticleDetailView
      article={article}
      isReplyUsed={isReplyUsed}
      boardNo={boardNo}
      articleNo={articleNo}
      popupMode={popupMode}
    />
  ) : (
    <></>
  );
}
