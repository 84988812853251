export const message = {
  // validation
  noInputId: '아이디를 입력해주세요.',
  noInputPassword: '비밀번호를 입력해주세요.',
  invalidId: '영문, 숫자, 특수문자(-),(_),(.),(@)만 입력가능 합니다. (@는 1개만 입력 가능)',
  lessLengthId: '아이디는 최소 5자 이상 입력해주세요.',
  existId: '이미 사용중인 아이디입니다. 다른 아이디를 입력하여 주세요.',
  withDrawn: '회원 탈퇴한 아이디 입니다. 탈퇴 후 동일한 아이디로 5일간 재가입 불가합니다.',
  kcpWithdrawn: '탈퇴한 회원입니다. 탈퇴 후 5일간 재가입 불가합니다.',
  kcpExistMember: '이미 가입된 회원입니다.',
  invalidPassword: '영문, 숫자, 특수문자를 3종류 모두 조합하여 8~20자로 입력해주세요.',
  lessInvalidPassword: '영문, 숫자, 특수문자를 2종류 이상 조합하여 10~20자로 입력해주세요.',
  invalidPasswordSpecial: '특수문자는 !@#$%^&+=-_.()만 사용 가능합니다.',
  notEqualPassword: '비밀번호와 비밀번호확인 값이 일치하지 않습니다.',
  notEqualNewPassword: '새 비밀번호와 새 비밀번호확인 값이 일치하지 않습니다.',
  noInputEmail: '이메일을 입력해주세요.',
  invalidEmail: '입력된 이메일은 잘못된 형식입니다.',
  existEmail: '이메일 주소가 이미 사용중입니다.',
  existNickname: '닉네임이 이미 사용중입니다.',
  invalidKorEngNum: '한글, 영문, 숫자만 입력 가능합니다.',
  invalidKorEng: '한글, 영문만 입력가능합니다.',
  invalidMobileNo: '11~12자 이내로 입력해주세요.',
  invalidAge: '만 14세 미만은 쇼핑몰 이용이 불가합니다.',
  invalidBirthday: '생년월일을 확인해주세요.',
  requiredBirthday: '생년월일을 선택해주세요.',
  successId: '사용 가능한 아이디입니다.',
  successEmail: '사용 가능한 이메일입니다.',
  successNickname: '사용 가능한 닉네임입니다.',
  // auth
  expiredTime: '유효시간 경과되었습니다. 다시 [인증번호 발송] 클릭하여 발급된 인증번호를 입력해주세요.',
  wrongAuthNumber: '올바른 인증번호가 아닙니다.',
  // error
  authError: '아이디 또는 비밀번호를 다시한번 확인해 주시기 바랍니다.',
  success: '',
} as const;

export default message;
