import React, { ChangeEvent, ChangeEventHandler, useState } from 'react';

import InputBtnGroup from '@/components/common/InputBtnGroup';
import RadioGroup, { OptionData } from '@/components/common/forms/RadioGroup';
import Label from '@/components/common/forms/Label';
import InputRow from '@/components/common/forms/input/InputRow';
import { sanitizeHTML } from '@/api/sanitize';
import { ncmBridge } from '@/index';
import { useNavigateBack } from '@/hooks/route';
import { useMutation } from 'react-query';
import { ErrorResponseType, postBoardArticleReport, PostBoardArticleReportRequest } from '@/api/shop/board';
import { useLocation } from 'react-router-dom';
import Checkbox from '@/components/common/forms/Checkbox';
import { useModal, useModalState } from '@/context/modal.context';
import { usePostProfileBlockedMembers } from '@/hooks/display/queries';

export const reasonType = {
  COPYRIGHT: '저작권 침해',
  SLANDER: '욕설 또는 비방',
  ETC: '기타',
};

const REPORT_ARTICLE_CLAIM_OPTIONS: OptionData<ReasonType>[] = [
  { value: 'COPYRIGHT', display: '저작권 침해' },
  { value: 'SLANDER', display: '욕설 또는 비방' },
  { value: 'ETC', display: '기타' },
];
export type ReasonType = keyof typeof reasonType;

type ReportArticleClaimStateParam = {
  boardNo: string;
  articleNo: string;
  registerNo: number;
};

const MAX_COMMENT_TEXT_COUNT_LIMIT = 1000;

export default function ReportArticleClaim() {
  const navigateBack = useNavigateBack();
  const [reportReason, setReportReason] = useState<ReasonType>('COPYRIGHT');
  const [content, setContent] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [textAreaCount, setTextAreaCount] = useState(0);
  const { state } = useLocation();
  const openModal = useModal();
  const { handleClose } = useModalState();
  const { mutate: postProfileBlockedMember } = usePostProfileBlockedMembers();

  const { boardNo = '', articleNo = '', registerNo = 0 } = state as ReportArticleClaimStateParam;
  const { mutate: reportBoardArticleClaim } = useMutation(
    ['postBoardArticleReportClaim'],
    (request: PostBoardArticleReportRequest) => postBoardArticleReport({ boardNo, articleNo }, request),
    {
      onSuccess: (res) => {
        if (res.status === 204) {
          if (isChecked) {
            postProfileBlockedMember(
              { requestBody: { blockedMemberNo: Number(registerNo) } },
              {
                onSuccess: () => {
                  ncmBridge.fetchSnackbarToNative('차단이 완료되었어요.');
                  ncmBridge.refreshProductList();
                  navigateBack();
                },
              },
            );
          } else {
            ncmBridge.fetchSnackbarToNative('신고가 접수되었어요.');
            navigateBack();
          }
        } else {
          const { error, code } = res as ErrorResponseType;
          if (error && code === 'B0015') {
            alert('이미 신고된 리뷰입니다.');
            navigateBack();
          }
        }
      },
    },
  );

  const handleReportReasonChange = (e: ChangeEvent<HTMLInputElement>) => {
    const reportReason = e.currentTarget.value as ReasonType;
    setReportReason(reportReason);
  };

  const handleTextareaChange: ChangeEventHandler<HTMLTextAreaElement> = (ev) => {
    const content = ev.target.value;
    let textValue = content;

    if (textValue.length > MAX_COMMENT_TEXT_COUNT_LIMIT) {
      textValue = content.substring(0, MAX_COMMENT_TEXT_COUNT_LIMIT);
    }

    setContent(sanitizeHTML(textValue));
    setTextAreaCount(textValue.length);
  };

  const handleReportArticleConfirm = async () => {
    const reportArticleClaimRequest = {
      reportReasonType: reportReason as ReasonType,
      content,
    };

    reportBoardArticleClaim(reportArticleClaimRequest);
  };

  const handleChangeBlockAuthor = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;

    if (checked) {
      openModal({
        theme: 'sm',
        type: 'confirm',
        mode: 'order',
        title: '작성자 차단',
        confirmBtnLabel: '차단',
        isOnlyConfirm: true,
        description:
          '이 회원이 등록한 모든 콘텐츠\n(아이템, 코멘트 등)를 보지 않으시겠어요?\n차단 후에는 다시 취소하실 수 없어요.',
        onConfirm: () => {
          setIsChecked(true);
          handleClose(true);
        },
      });
    } else {
      setIsChecked(false);
    }
  };

  return (
    <div className="modal-product-review-report">
      <div className="report-radio">
        <RadioGroup
          options={REPORT_ARTICLE_CLAIM_OPTIONS}
          name="reviewReport-method"
          currentValue={reportReason}
          onChange={(e) => handleReportReasonChange(e)}
        />
      </div>
      <InputRow>
        <Label lbFor="content" txt="상세 사유" />
        <span className="input-field">
          <textarea
            className="textarea"
            value={content}
            placeholder="상세 사유를 입력해 주세요."
            onChange={handleTextareaChange}
          />
        </span>
        <span className="count">{textAreaCount}/1000</span>
      </InputRow>

      <div className="reiew-wirte__report">
        <Checkbox isChecked={isChecked} onCheckboxInputChange={handleChangeBlockAuthor} txt="작성자 차단" />
      </div>

      <InputBtnGroup
        confirmTxt="신고"
        onConfirmBtnClick={() => handleReportArticleConfirm()}
        onCancelBtnClick={() => navigateBack()}
      />
    </div>
  );
}
